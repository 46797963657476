import ListingStore from "@stores/ListingStore";
import { apiHelper } from "@helpers/apiHelper";
import SfmModalStore from "@stores/SfmModalStore";
import { GetSFMDisplay, isSfmSuccess } from "@helpers/helpers";
const ttpRequestId = require("uuid").v4();

const STORE_LIMIT = 50;

class Store extends ListingStore {
  microserviceInput = "";
  dataKey = "ReconciliationRuns";
  limit = STORE_LIMIT;
  offset = 0;
  setMicroservice(microservice) {
    this.microserviceInput = microservice;
  }

  async getData() {
    const externalurl = `/api/v1/Reconciliation/GetReconciliationRuns`;
    const activityTag = "GetReconciliationRuns";

    const apiRequest = apiHelper({
      externalurl,
      activityTag,
      filter: {
        enabled: true,
        value: this.filter,
      },
      sort: {
        enabled: true,
        value: this.sort,
      },
      limit: this.limit,
      offset: this.offset,
    });

    return apiRequest
      .then((data) => {
        const sfmData = GetSFMDisplay(data.SFM);
        if (!isSfmSuccess(sfmData)) {
          SfmModalStore.open(sfmData);
          return{
            data: [],
            total: 0,
            page: 1,
          }
        } else {
          return {
            data: data.ReconciliationRuns,
            total: data.ReconciliationRuns.length, // You might need to adjust this depending on the actual response structure
            page: 1, // Adjust if pagination data is available in the response
          };
        }
      })

      .catch((error) => {
        return;
      });
  }
}

const myStore = new Store();

export default myStore;

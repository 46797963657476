import React, { useEffect, useState } from "react";
import CustomTable from "@components/CustomTable";
import myStore from "./store";
import { Observer } from "mobx-react";
import ButtonComponent from "@components/ButtonComponent";
import {
  ResetReconciliationStatistics,
  TriggerManualDataAcquisition,
  SkipNextDataAcquisitionInstance,
  GetSFMDisplay,
  DropDataAcquisitionFile,
  resolveStatusColumn,
} from "@helpers/helpers";
import SfmModalStore from "@stores/SfmModalStore";
import PopupComponent from "@components/PopupComponent";
import TitleComponent from "@components/TitleComponent";
import Confirmationpopup from "@components/ConfirmationPopup";
import LegendComponent from "@components/LegendComponent";

import { FileUploader } from "react-drag-drop-files";

import { useTranslation } from "react-i18next";

const DEFAULT_MONTH_RANGE = 6;
const TRIM_CURRENT_DATE = 16;

const DataAcquisitions = () => {
  const { t: translate, i18n } = useTranslation();
  const statusColors = {
    Active: "bg-green-400",
    Disabled: "bg-red-400",
    "On Hold": "bg-gray-400",
  };

  const [currentDateTime, setCurrentDateTime] = useState("");
  const [file, setFile] = useState({});
  const [openConfirmationModal, setOpenConfirmation] = useState(null);
  const [currentDataAcquisitionId, setCurrentDataAcquisitionId] =
    useState(null);
  const [openFileDropModal, setOpenFileDropModal] = useState(false);
  const [refreshFileDrop, setRefreshFileDrop] = useState(false);

  useEffect(() => {
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - DEFAULT_MONTH_RANGE);

    const formattedDateTime = today.toISOString().slice(0, TRIM_CURRENT_DATE);
    setCurrentDateTime(formattedDateTime);
  }, []);

  const pinningInitialState = ["pin", "DataAcquisitionStatusTag"];

  const legendData = [];

  const columns = [
    {
      accessorKey: "DataAcquisitionName",
      header: translate("data-acquisitions.columns.DataAcquisitionName"),
      canFilter: false,
      isMultiple: false,
      sortable: true,
      size: 250,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.DataAcquisitionTag;
      },
      processFilterKey: (value, row) => {
        return "DataAcquisitionTag";
      },
    },
    {
      accessorKey: "IsScheduled",
      header: translate("data-acquisitions.columns.Schedule"),
      size: 180,
      sortable: false,
      canPin: false,
      cell: ({ row }) => {
        return (
          <span
            className={`rounded-full flex items-end justify-center gap-1 w-full font-normal text-sm border border-r-10 border-transparent `}
          >
            {/* {row.original.IsScheduled ? ( */}
            <div className="flex flex-col gap-2">
              {row.original.PeriodicityName}{" "}
              <span className="font-semibold">
                {row.original.AllowManualTrigger &&
                  translate("data-acquisitions.legend.onDemand")}{" "}
                {row.original.AllowFileDrop &&
                  translate("data-acquisitions.legend.manual")}
              </span>
            </div>
            {/* // ) : (
            //   <>{translate("data-acquisitions.legend.notScheduled")}</>
            // )} */}
          </span>
        );
      },
    },
    {
      accessorKey: "NextAcquisition",
      header: translate("data-acquisitions.columns.NextAcquisition"),
      size: 150,
      sortable: true,
      canFilter: false,
      splitCellValue: "T",
      hideCopy: true,
    },
    {
      accessorKey: "LastSuccessfulAcquisition",
      header: translate("data-acquisitions.columns.LastSuccessfulAcquisition"),
      size: 210,
      sortable: true,
      truncate: true,
      canFilter: false,
      splitCellValue: "T",
      hideCopy: true,
    },
    {
      accessorKey: "LastDuration",
      header: translate("data-acquisitions.columns.LastDuration"),
      size: 130,
      sortable: true,
      truncate: true,
      canFilter: false,
      hideCopy: true,
    },
    {
      accessorKey: "DataAcquisitionStatusTag",
      header: translate("data-acquisitions.columns.Status"),
      headerHelper: () => {
        return <LegendComponent data={legendData} />;
      },
      size: 50,
      sortable: true,
      canFilter: false,
      // hideHeader: true,
      hideCopy: true,
      processFilterValue: (value, row) => {
        return row.DataAcquisitionStatusTag;
      },
      processFilterKey: (value, row) => {
        return "DataAcquisitionStatusTag";
      },
      cell: ({ row }) => {
        return resolveStatusColumn(
          row.original.DataAcquisitionStatusName,
          row.original.DataAcquisitionStatusTag,
          ["INPROGRESS"],
          null,
          row.original.DataAcquisitionInstanceStatusTag
        );
      },
    },
    {
      accessorKey: "Statistics",
      header: translate("data-acquisitions.columns.Statistics"),
      size: 150,
      canFilter: false,
      truncate: true,
      processFilterKey: (value, row) => {
        return "FailuresPercentage";
      },
      processFilterValue: (value) => {
        return value[0].StatisticValues.find(
          (element) => element.Key === "FailuresPercentage"
        ).Value;
      },
      extraClass: "w-full",
      cell: ({ row }) => {
        const percentage =
          (!!row.original.Statistics[0] &&
            row.original.Statistics[0].StatisticValues.find(
              (element) => element.Key === "FailuresPercentage"
            ).Value) ||
          "0.00%";
        return (
          <div
            className={`w-full rounded-full flex items-end justify-center gap-1  font-normal text-sm border border-r-10 border-transparent `}
          >
            <div className="flex w-full flex-col items-center">
              <div className="w-full relative bg-gray-200 rounded-full dark:bg-gray-700 overflow-hidden">
                <div
                  className="bg-indigo-400 absolute h-full text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
                  style={{ width: `${percentage}` }}
                >
                  {" "}
                </div>
                <div className="relative text-white z-2">{percentage}</div>
              </div>
              {percentage !== 0 && percentage !== "0.00%" && (
                <ButtonComponent
                  withoutBorder
                  type="reset"
                  id="reset-percentage"
                  onClick={() =>
                    setOpenConfirmation(actions["RESET_PERCENTAGE"](row))
                  }
                  className="btn text-indigo-400 mt-2"
                  ref={null}
                  icon={null}
                  text={translate("data-acquisitions.buttons.reset")}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const fileTypes = ["CSV"];

  const handleChange = (file) => {
    setFile(file);
    setOpenConfirmation({
      ...actions["FILE_DROP"],
      actionFN: () => handleFileDrop(file),
      cancelAction: () => setRefreshFileDrop(!refreshFileDrop),
      confirmationMessage: `The data promotion/acquisition process will commence. Please confirm if you wish to proceed with the file - ${file.name}`,
    });
  };

  const actions = {
    FILE_DROP: {
      actionTypeTag: "FILE_DROP",
      name: translate("data-acquisitions.actions.FILE_DROP"),
      actionFN: (row) => {
        setOpenFileDropModal(true);
        setCurrentDataAcquisitionId(row.original.DataAcquisitionId);
        myStore.loading = false;
      },
      // confirmation: true,
    },
    RUN_NOW: {
      actionTypeTag: "RUN_NOW",
      name: translate("data-acquisitions.actions.RUN_NOW"),
      actionFN: (row) => {
        myStore.loading = true;
        TriggerManualDataAcquisition(
          row.original.DataAcquisitionId,
          (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData, () => {
              window.location.href = "/data-acquisitions";
            });
          },
          myStore
        );
      },
      confirmationMessage: translate(
        "data-acquisitions.confirmation-messages.RUN_NOW"
      ),
      confirmation: true,
    },
    SKIP_NEXT_INSTANCE: {
      actionTypeTag: "SKIP_NEXT_INSTANCE",
      name: translate("data-acquisitions.actions.SKIP_NEXT_INSTANCE"),
      actionFN: (row) => {
        myStore.loading = true;
        SkipNextDataAcquisitionInstance(
          row.original.DataAcquisitionId,
          (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData, () => {
              window.location.href = "/data-acquisitions";
            });
          },
          myStore
        );
      },
      confirmationMessage: translate(
        "data-acquisitions.confirmation-messages.SKIP_NEXT_INSTANCE"
      ),
      confirmation: true,
    },
    RESET_PERCENTAGE: (row) => {
      return {
        actionTypeTag: "RESET_PERCENTAGE",
        name: translate("data-acquisitions.actions.RESET_PERCENTAGE"),
        actionFN: () => {
          myStore.loading = true;
          ResetReconciliationStatistics(
            row.original.Statistics?.[0]?.StatisticTag,
            row.original.DataAcquisitionId.toString(),
            (data) => {
              const sfmData = GetSFMDisplay(data.SFM);
              SfmModalStore.open(sfmData, () => {
                window.location.href = "/data-acquisitions";
              });
            },
            myStore
          );
        },
        confirmationMessage: translate(
          "data-acquisitions.confirmation-messages.RESET_PERCENTAGE"
        ),
        confirmation: true,
      };
    },
  };

  const multipleValueColumns = columns
    .filter((column) => column.isMultiple)
    .map((column) => column.accessorKey);

  const handleFileDrop = (droppedFile) => {
    myStore.loading = true;
    DropDataAcquisitionFile(
      currentDataAcquisitionId,
      droppedFile,
      (data) => {
        const sfmData = GetSFMDisplay(data.SFM);
        SfmModalStore.open(sfmData, () => {
          window.location.href = "/data-acquisitions";
        });
      },
      myStore
    );
  };

  return (
    <Observer>
      {() => {
        return (
          <>
            <CustomTable
              store={myStore}
              columnsWithoutPin={columns}
              loading={myStore.loading}
              pinCondition={(row) => {
                return false;
              }}
              filterDisplayValues={{}}
              selectedFiltersDisplay={{}}
              filters={{}}
              pinningInitialState={pinningInitialState}
              setParentSelectedValues={() => {}}
              actions={actions}
              prefix="DataAcquisitions"
              title={translate("data-acquisitions.title")}
              multipleValueColumns={multipleValueColumns}
              pinnedRowHeight={62}
              // maxTableHeight={450}
              maxTableHeight={"calc(100dvh - 400px)"}
              showFilter={false}
              language={i18n.language}
              hideActionOnRow={(row) => {
                const statusTag = row.original.DataAcquisitionStatusTag;
                return statusTag === "DISABLED";
              }}
            />
            {/* {!myStore.loading && (
              <div className="container mx-auto">
                <div className="mx-auto w-[90%] pt-5">
                  <LegendComponent data={legendData} inline />
                </div>
              </div>
            )} */}
            {openFileDropModal && (
              <PopupComponent
                externalClassName={"w-[40vw] !max-w-[550px]"}
                open={openFileDropModal}
                rawValue={
                  <>
                    <div className="py-5">
                      <TitleComponent
                        text="Upload Data Acquisition File"
                        size="lg"
                        description="Manually upload a data acquisition file."
                      />
                    </div>
                    <FileUploader
                      key={`fileDrop_${refreshFileDrop}`}
                      classes="h-[100px]"
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    />
                  </>
                }
                setPopupOpen={setOpenFileDropModal}
                addBlur
                hideCellValue={true}
              />
            )}
            {openConfirmationModal && (
              <Confirmationpopup
                singleAction={openConfirmationModal}
                setOpenConfirmationAction={setOpenConfirmation}
              />
            )}
          </>
        );
      }}
    </Observer>
  );
};

export default DataAcquisitions;

import React, { useState } from "react";
import { skipNextCutoff, GetSFMDisplay } from "@helpers/helpers";
import SfmModalStore from "@stores/SfmModalStore";
import Loader from "@components/LoaderComponent/loader";
import Confirmationpopup from "@components/ConfirmationPopup";
import ButtonComponent from "@components/ButtonComponent";
import { useTranslation } from "react-i18next";

const BusinessDayComponent = ({ data, reloadCutoffData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmationAction, setOpenConfirmationAction] = useState(false);
  
  const { t: translate } = useTranslation();

  // Function to format the date and time
  const formadivate = (dateTime) => {
    const date = new Date(dateTime);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { formattedDate, formattedTime };
  };

  const skip = () => {
    setIsLoading(true);
    skipNextCutoff((data) => {
      const sfmData = GetSFMDisplay(data.SFM);
      setIsLoading(false);
      SfmModalStore.open(sfmData, ()=>{
        if (reloadCutoffData) {
          setIsLoading(true);
          reloadCutoffData();
        }
      });
    });
  };

  return (
    <div className="flex gap-2">
      <div className="flex gap-4 items-center">
        <div className="flex gap-2 flex-col border-gray-300 rounded p-2 w-full min-w-max">
          <div className="flex flex-col items-start">
            <h2 className="font-semibold text-xs mb-1 text-center">
              {translate('business-day.business-day')}:
            </h2>
            <div className="flex justify-center">
              <div className="text-sm ">
                {data.CurrentBusinessDay} (
                <span className="text-gray-600">
                  {data.CurrentBusinessDayInstance})
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <div className="font-semibold text-center text-xs">
              {translate('business-day.current-cutoff')}:
            </div>
            <div className="flex gap-1">
              <div className="text-sm">
                {data.CutoffDate?.split('T')[0]}
              </div>
              <div className="text-sm text-gray-600">
              {formadivate(data.CutoffDate).formattedTime}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 flex-col border-gray-300 rounded p-2 w-full min-w-max">
          <div className="flex flex-col items-start">
            <h2 className="font-semibold text-xs mb-1 text-center">
            {translate('business-day.next-day')}:
            </h2>
            {openConfirmationAction && (
              <Confirmationpopup
                singleAction={{
                  actionFN: skip,
                  confirmation: true,
                  confirmationMessage: translate('reconciliation-runs.confirmation-messages.SKIP_NEXT_INSTANCE'),
                  severity: "high",
                }}
                setOpenConfirmationAction={setOpenConfirmationAction}
              />
            )}
            <div className="flex justify-center">
              <div className="text-sm">
                {data.NextBusinessDay} (
                <span className="text-gray-600">
                  {data.NextBusinessDayInstance})
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <div className="font-semibold text-center text-xs">
            {translate('business-day.next-cutoff')}:
            </div>
            <div className="flex gap-1">
              <div className="text-sm">
              {data.NextCutoffDate?.split('T')[0]}
              </div>
              <div className="text-sm text-gray-600">
                {formadivate(data.NextCutoffDate).formattedTime}
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="p-4 min-w-[155px]">
            <Loader />
          </div>
        ) : (
          <ButtonComponent
            className="text-sm min-w-max h-fit p-2 rounded-lg px-4 cursor-pointer bg-white"
            withoutBorder={true}
            id="skip-day"
            color="blue"
            onClick={() => setOpenConfirmationAction(true)}
            ref={null}
            icon={null}
            text={translate('business-day.actions.skip')}
          />
        )}
      </div>
    </div>
  );
};

export default BusinessDayComponent;

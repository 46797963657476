// export const analyzeDataOLD = (response) => {
//   const chartSettings = response.Chart.ChartSettings;
//   const dataSets = response.Chart.DataSets;
//   console.log('nadim', dataSets)
//   // Extract X-axis categories
//   const categories = chartSettings["X-Axis"].ScaleMarkers.map(marker => marker.Label);

//   // Generate chartOptions
//   const chartOptions = {
//     chart: {
//       type: 'line',
//       height: 350,
//       zoom: {
//         enabled: false // Disable range zooming
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '30%', // Narrow down the width of the bars
//         endingShape: 'rounded'
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       enabledOnSeries: [1, 2], // Enable data labels only for the line series
//       offsetX: 0,
//       offsetY: -10, // Adjust the vertical position of the data labels
//       style: {
//         fontSize: "15px",
//         fontFamily: "Helvetica, Arial, sans-serif",
//         fontWeight: "bold",
//         // colors: ["#ffbf00", "#fe0509"]
//         colors: undefined
//       },
//       background: {
//         enabled: true,
//         foreColor: undefined,
//         padding: 5,
//         borderRadius: 2,
//         borderWidth: 0,
//         borderColor: "#fff",
//         opacity: 0.9,
//         dropShadow: {
//           enabled: false,
//           top: 1,
//           left: 1,
//           blur: 1,
//           color: "#000",
//           opacity: 0.45,
//         },
//       },
//       dropShadow: {
//         enabled: false,
//         top: 0,
//         left: 0,
//         blur: 1,
//         color: "black",
//         opacity: 0.3,
//       },
//       formatter: function (val, opts) {
//         return `${val.toFixed(2)}%`; // Format the data labels to 2 decimal places
//       },

//     },
//     stroke: {
//       show: true,
//       width: [0, 4, 4],
//       curve: 'straight' // Make the lines straight without border radius
//     },
//     colors: dataSets.map(set => {

//       return set.Color || set.Settings.Color
//     }), // Use colors from the dataset
//     xaxis: {
//       categories: categories,
//       title: {
//         text: chartSettings["X-Axis"].Label,
//       },
//     },
//     yaxis: chartSettings["Y-Axis"].map(axis => ({
//       title: {
//         text: axis.Label,
//       },
//       opposite: axis.Position === "RIGHT",
//       labels: {
//         formatter: function (val) {
//           return axis.Tag === "VOLUME_PERCENTAGE" ? `${val.toFixed(2)}%` : val.toLocaleString(); // Format the Y-axis labels
//         }
//       }
//     })),
//     fill: {
//       opacity: [1, 0.8, 0.8],
//     },
//     tooltip: {
//       shared: true,
//       intersect: false,
//       custom: function({ series, seriesIndex, dataPointIndex, w }) {
//         let tooltipContent = `<div class="flex flex-col gap-1 py-3 px-5" style="background: rgba(255, 255, 255, 0.5); border-radius: 5px;">
//                                 <span class="tooltip-category">${w.globals.labels[dataPointIndex]}</span>`;
//         w.globals.seriesNames.forEach((seriesName, i) => {
//           const value = series[i][dataPointIndex];
//           let formattedValue = value.toLocaleString();
//           if (seriesName === 'In-transit Volume' || seriesName === 'Exceptions Volume') {
//             const recordCount = (value / 100 * w.globals.series[0][dataPointIndex]).toLocaleString();
//             formattedValue = `${value.toFixed(2)}% (${recordCount} Records)`;
//           } else if (seriesName === 'Business Day Values') {
//             formattedValue = `${value.toLocaleString()} Records`;
//           }
//           tooltipContent += `<span class="tooltip-series"><span class="text-sm">${seriesName}:</span><span class="text-md font-semibold">${formattedValue}</span></span>`;
//         });
//         tooltipContent += `</div>`;
//         return tooltipContent;
//       }
//     },
//     legend: {
//       position: 'bottom', // Move the legend to the bottom
//       horizontalAlign: 'center', // Center align the legend
//       offsetY: 10
//     }
//   };

//   // Generate chartSeries
//   const chartSeries = dataSets.map(set => ({
//     name: set.Settings.Label,
//     type: set.Settings.GraphTypeTag.toLowerCase(),
//     data: set.Data.map(dataPoint => dataPoint.Value)
//   }));

//   return { chartOptions, chartSeries };
// };
// export const analyzeData = (response, showPopup = () => {}) => {
//   const chartSettings = response.Chart;
//   const dataSets = response.Chart.DataSets;

//   // Extract X-axis categories
//   const categories = chartSettings.XAxis.ScaleMarkers.map(
//     (marker) => marker.Label
//   );

//   const pointClick = (event, chartContext, { seriesIndex, dataPointIndex }) => {
//     const dataSet = dataSets[seriesIndex];
//     const dataPoint = dataSet?.Data[dataPointIndex];
//     const popup = dataPoint?.Popup;

//     if (popup) {
//       let popupContent = `<div class="popup-content">
//                             <h3><b><u>${popup.Label}</u></b></h3>
//                             <p>${popup.ShortDescription}</p>
//                             <ul class="mt-2 gap-1">`;
//       popup.Attributes.forEach((attr) => {
//         popupContent += `<li><strong>${attr.Label}:</strong> ${attr.Value}</li>`;
//       });
//       popupContent += `</ul></div>`;

//       // Display the popup content (you can use a modal or any other method to display the content)
//       // alert(popupContent);
//       showPopup(popupContent);
//     } else{
//       // showPopup(null);
//     }
//   };
//   // Generate chartOptions
//   const chartOptions = {
//     chart: {
//       type: "line",
//       height: 350,
//       zoom: {
//         enabled: false, // Disable range zooming
//       },
//       events: {
//         markerClick: function (
//           event,
//           chartContext,
//           { seriesIndex, dataPointIndex }
//         ) {
//           pointClick(event, chartContext, { seriesIndex, dataPointIndex });
//         },
//         legendClick: function (
//           event,
//           chartContext,
//           { seriesIndex, dataPointIndex }
//         ) {
//           pointClick(event, chartContext, { seriesIndex, dataPointIndex });
//         },
//         xAxisLabelClick: function (pointerEvent, opts, { labelIndex }) {
//           const marker = chartSettings.XAxis.ScaleMarkers[labelIndex];

//           const popup = marker.Popup;

//           if (popup) {
//             let popupContent = `<div class="popup-content">
//                                   <h3><b><u>${popup.Label}</u></b></h3>
//                                   <p>${popup.ShortDescription}</p>
//                                   <ul class="mt-2 gap-1">`;
//             popup.Attributes.forEach((attr) => {
//               popupContent += `<li><strong>${attr.Label}:</strong> ${attr.Value}</li>`;
//             });
//             popupContent += `</ul></div>`;

//             // Display the popup content
//             showPopup(popupContent);
//           } else {
//             showPopup(null);
//           }
//         },
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: "30%", // Narrow down the width of the bars
//         endingShape: "rounded",
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       enabledOnSeries: dataSets
//         .map((set, index) => (set.ElementTag === "LINE" ? index : null))
//         .filter((index) => index !== null), // Enable data labels only for the line series
//       offsetX: 0,
//       offsetY: -5, // Adjust the vertical position of the data labels
//       style: {
//         fontSize: "12px",
//         fontFamily: "Helvetica, Arial, sans-serif",
//         fontWeight: "normal",
//         colors: undefined,
//         cursor: 'pointer'
//       },
//       background: {
//         enabled: true,
//         foreColor: undefined,
//         padding: 2,
//         borderRadius: 2,
//         borderWidth: 0,
//         borderColor: "#d6d6d6",
//         opacity: 1,
//         dropShadow: {
//           enabled: false,
//           top: 1,
//           left: 1,
//           blur: 1,
//           color: "#000",
//           opacity: 0.45,
//         },
//       },
//       dropShadow: {
//         enabled: false,
//         top: 0,
//         left: 0,
//         blur: 1,
//         color: "black",
//         opacity: 0.3,
//       },
//       formatter: function (val, opts) {
//         return `${val.toFixed(2)}%`; // Format the data labels to 2 decimal places
//       },
//     },
//     stroke: {
//       show: true,
//       width: dataSets.map((set) => (set.ElementTag === "LINE" ? 4 : 0)),
//       curve: "straight", // Make the lines straight without border radius
//     },
//     markers: {
//       size: 6, // Size of the markers
//       shape: "square", // Shape of the markers
//       hover: {
//         size: 7, // Size of the markers on hover
//         sizeOffset: 3 // Offset of the markers on hover
//       }
//     },
//     colors: dataSets.map((set) => set.ElementColor), // Use colors from the dataset
//     xaxis: {
//       categories: categories,
//       title: {
//         text: chartSettings.XAxis.Label,
//       },
//       labels: {
//         style: {
//           fontSize: "12px",
//           fontFamily: "Helvetica, Arial, sans-serif",
//           fontWeight: "bold",
//           colors: undefined,
//           cssClass: 'cursor-pointer',
//         },
//         // formatter: function (value) {
//         //   return `${value} 112`;
//         // }
//       },
//     },
//     yaxis: chartSettings.YAxis.map((axis) => ({
//       title: {
//         text: axis.Label,
//       },
//       opposite: axis.Position === "RIGHT",
//       labels: {
//         formatter: function (val) {
//           return axis.Tag === "VOLUME_PERCENTAGE"
//             ? `${val.toFixed(2)}%`
//             : val.toLocaleString(); // Format the Y-axis labels
//         },
//       },
//     })),
//     fill: {
//       opacity: [1, 0.8, 0.8],
//     },
//     tooltip: {
//       shared: true,
//       intersect: false,
//       custom: function ({ series, seriesIndex, dataPointIndex, w }) {
//         let tooltipContent = `<div class="flex flex-col gap-1 py-3 px-5" style="background: rgba(255, 255, 255, 0.5); border-radius: 5px;">
//                                 <span class="tooltip-category">${w.globals.labels[dataPointIndex]}</span>`;
//         w.globals.seriesNames.forEach((seriesName, i) => {
//           const value = series[i][dataPointIndex];
//           let formattedValue = value.toLocaleString();
//           if (
//             seriesName === "In-transit Volume" ||
//             seriesName === "Exceptions Volume"
//           ) {
//             const recordCount = (
//               (value / 100) *
//               w.globals.series[0][dataPointIndex]
//             ).toLocaleString();
//             formattedValue = `${value.toFixed(2)}% (${recordCount} Records)`;
//           } else if (seriesName === "Business Day Values") {
//             formattedValue = `${value.toLocaleString()} Records`;
//           }
//           tooltipContent += `<span class="tooltip-series"><span class="text-sm">${seriesName}:</span><span class="text-md font-semibold">${formattedValue}</span></span>`;
//         });
//         tooltipContent += `</div>`;
//         return tooltipContent;
//       },
//     },
//     legend: {
//       position: "bottom", // Move the legend to the bottom
//       horizontalAlign: "center", // Center align the legend
//       offsetY: 10,
//     },
//   };

//   // Generate chartSeries
//   const chartSeries = dataSets.map((set) => ({
//     name: set.Label,
//     type: set.ElementTag.toLowerCase(),
//     data: set.Data.map((dataPoint) => dataPoint.Value),
//   }));

//   return { chartOptions, chartSeries };
// };
export const analyzeData = (response, showPopup = () => {}) => {
  const chartSettings = response.Chart;
  const dataSets = response.Chart.DataSets;
  const legends = response.Chart.Legends;

  // Extract X-axis categories
  const categories = chartSettings.XAxis.ScaleMarkers.map(
    (marker) => marker.Label
  );

  const pointClick = (event, chartContext, { seriesIndex, dataPointIndex }) => {
    const dataSet = dataSets[seriesIndex];
    const dataPoint = dataSet?.Data[dataPointIndex];
    const popup = dataPoint?.Popup;

    if (popup) {
      let popupContent = `<div class="popup-content">
                            <h3><b><u>${popup.Label}</u></b></h3>
                            <p>${popup.ShortDescription}</p>
                            <ul class="mt-2 gap-1">`;
      popup.Attributes.forEach((attr) => {
        // popupContent += `<li><strong>${attr.Label}:</strong> ${attr.Value}</li>`;
        popupContent += `<li>
                            <strong>${attr.Label}:</strong> 
                            <span class="${
                              parseInt(attr.Value) < 0
                                ? "text-red-500"
                                : attr.Tag === "VARIANCE_AMOUNT"
                                ? "text-green-500"
                                : "text-gray-700"
                            }">${attr.Value}</span>
                          </li>`;
      });
      popupContent += `</ul></div>`;

      // Display the popup content (you can use a modal or any other method to display the content)
      // alert(popupContent);
      showPopup(popupContent);
    } else {
      // showPopup(null);
    }
  };
  // Generate chartOptions
  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      // zoom: {
      //   enabled: false, // Disable range zooming
      // },
      events: {
        markerClick: function (
          event,
          chartContext,
          { seriesIndex, dataPointIndex }
        ) {
          pointClick(event, chartContext, { seriesIndex, dataPointIndex });
        },
        click: function (event, chartContext, { seriesIndex, dataPointIndex }) {
          pointClick(event, chartContext, { seriesIndex, dataPointIndex });
        },
        // legendClick: function (
        //   event,
        //   chartContext,
        //   { seriesIndex, dataPointIndex }
        // ) {
        //   pointClick(event, chartContext, { seriesIndex, dataPointIndex });
        // },
        legendClick: function (chartContext, seriesIndex, config) {
          const series = chartContext.w.globals.series;
          const seriesName = chartContext.w.globals.seriesNames[seriesIndex];
          const isVisible =
            chartContext.w.globals.series[seriesIndex].length > 0;
          console.log("test", seriesName, isVisible);
          if (isVisible) {
            chartContext.hideSeries(seriesName);
          } else {
            chartContext.showSeries(seriesName);
          }
        },
        xAxisLabelClick: function (pointerEvent, opts, { labelIndex }) {
          const marker = chartSettings.XAxis.ScaleMarkers[labelIndex];

          const popup = marker.Popup;

          if (popup) {
            let popupContent = `<div class="popup-content">
                                  <h3><b><u>${popup.Label}</u></b></h3>
                                  <p>${popup.ShortDescription}</p>
                                  <ul class="mt-2 gap-1">`;
            popup.Attributes.forEach((attr) => {
              popupContent += `<li>
                                  <strong>${attr.Label}:</strong> 
                                  <span class="${
                                    parseInt(attr.Value) < 0
                                      ? "text-red-500"
                                      : attr.Tag === "VARIANCE_AMOUNT"
                                      ? "text-green-500"
                                      : "text-gray-700"
                                  }">${attr.Value}</span>
                                </li>`;
            });
            popupContent += `</ul></div>`;

            // Display the popup content
            showPopup(popupContent);
          } else {
            showPopup(null);
          }
        },
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        },
        export: {
          csv: {
            filename: chartSettings.Label,
            columnDelimiter: ',',
            // headerCategory: 'category',
            // headerValue: 'value',
            // categoryFormatter(x) {
            //   return new Date(x).toDateString()
            // },
            // valueFormatter(y) {
            //   return y
            // }
          },
          svg: {
            filename: chartSettings.Label,
          },
          png: {
            filename: chartSettings.Label,
          }
        },
        // autoSelected: 'zoom' 
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%", // Narrow down the width of the bars
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: dataSets
        .map((set, index) => (set.ElementTag === "LINE" ? index : null))
        .filter((index) => index !== null), // Enable data labels only for the line series
      offsetX: 0,
      offsetY: -5, // Adjust the vertical position of the data labels
      style: {
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "normal",
        colors: undefined,
        cursor: "pointer",
      },
      background: {
        enabled: true,
        foreColor: undefined,
        padding: 2,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#d6d6d6",
        opacity: 1,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        color: "black",
        opacity: 0.3,
      },
      formatter: function (val, opts) {
        return `${val.toFixed(2)}%`; // Format the data labels to 2 decimal places
      },
    },
    stroke: {
      show: true,
      width: dataSets.map((set) => (set.ElementTag === "LINE" ? 3 : 0)),
      // curve: "smooth", // Make the lines straight without border radius
      curve: "straight", // Make the lines straight without border radius
    },
    markers: {
      strokeColors: "#444343",
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      size: 7, // Size of the markers
      shape: "triangle", // Shape of the markers
      hover: {
        size: 9, // Size of the markers on hover
        sizeOffset: 3, // Offset of the markers on hover
      },
    },
    // colors: dataSets.map((set) => set.ElementColor), // Use colors from the dataset
    colors: legends.map((set) => set.KeyColor), // Use colors from legends
    title: {
      text: chartSettings.Label,
      align: "center",
      margin: 30,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "22px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: chartSettings.XAxis.Label,
        offsetY: -5,
        style: {
          color: "#263238",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "font-semibold text-md",
        },
      },
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: undefined,
          cssClass: "cursor-pointer",
        },
        // formatter: function (value) {
        //   return `${value} 112`;
        // }
      },
    },
    yaxis: chartSettings.YAxis.map((axis) => ({
      title: {
        text: axis.Label,
      },
      // max: axis.Tag.indexOf('PERCENTAGE') > -1 ? 100 : dataSets.find(set => set.YAxisTag === axis.Tag).Data.reduce((max, dataPoint) => Math.max(max, dataPoint.Value), 0),
      max: axis.Tag.indexOf("PERCENTAGE") > -1 ? 100 : undefined,
      opposite: axis.Position === "RIGHT",

      // labels: {
      //   formatter: function (val) {
      //     return axis.Tag === "VOLUME_PERCENTAGE"
      //       ? `${val.toFixed(2)}%`
      //       : val.toLocaleString(); // Format the Y-axis labels
      //   },
      // },
    })),
    fill: {
      opacity: [1, 0.8, 0.8],
    },
    tooltip: {
      shared: false,
      // intersect: false,
      // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      //   debugger
      //   let tooltipContent = `<div class="flex flex-col gap-1 py-3 px-5" style="background: rgba(255, 255, 255, 0.5); border-radius: 5px;">
      //                           <span class="tooltip-category">${w.globals.labels[dataPointIndex]}</span>`;
      //   w.globals.seriesNames.forEach((seriesName, i) => {
      //     const value = series[i][dataPointIndex];
      //     let formattedValue = value.toLocaleString();
      //     if (
      //       seriesName === "In-transit Volume" ||
      //       seriesName === "Exceptions Volume"
      //     ) {
      //       const recordCount = (
      //         (value / 100) *
      //         w.globals.series[0][dataPointIndex]
      //       ).toLocaleString();
      //       formattedValue = `${value.toFixed(2)}% (${recordCount} Records)`;
      //     } else if (seriesName === "Business Day Values") {
      //       formattedValue = `${value.toLocaleString()} Records`;
      //     }
      //     tooltipContent += `<span class="tooltip-series"><span class="text-sm">${seriesName}:</span><span class="text-md font-semibold">${formattedValue}</span></span>`;
      //   });
      //   tooltipContent += `</div>`;
      //   return tooltipContent;
      // },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = dataSets[seriesIndex].Data[dataPointIndex];
        if (dataPoint.Tooltip) {
          let tooltipContent = `<div class="tooltip-content px-2 py-3">
                                  <h3><b><u>${dataPoint.Tooltip.Label}</u></b></h3>
                                  <p>${dataPoint.Tooltip.ShortDescription}</p>
                                  <ul class="mt-2 gap-1">`;
          dataPoint.Tooltip.Attributes.forEach((attr) => {
            tooltipContent += `<li>
                                <strong>${attr.Label}:</strong> 
                                <span class="${
                                  parseInt(attr.Value) < 0
                                    ? "text-red-500"
                                    : attr.Tag === "VARIANCE_AMOUNT"
                                    ? "text-green-500"
                                    : "text-gray-700"
                                }">${attr.Value}</span>
                              </li>`;
          });
          tooltipContent += `</ul></div>`;
          return tooltipContent;
        }
        return "";
        // else {
        //   // Return default tooltip
        //   let defaultTooltipContent = `<div class="flex flex-col gap-1 py-3 px-5" style="background: rgba(255, 255, 255, 0.5); border-radius: 5px;">
        //                                 <span class="tooltip-category">${w.globals.labels[dataPointIndex]}</span>`;
        //   w.globals.seriesNames.forEach((seriesName, i) => {
        //     const value = series[i][dataPointIndex];
        //     let formattedValue = value.toLocaleString();
        //     if (
        //       seriesName === "In-transit Volume" ||
        //       seriesName === "Exceptions Volume"
        //     ) {
        //       const recordCount = (
        //         (value / 100) *
        //         w.globals.series[0][dataPointIndex]
        //       ).toLocaleString();
        //       formattedValue = `${value.toFixed(2)}% (${recordCount} Records)`;
        //     } else if (seriesName === "Business Day Values") {
        //       formattedValue = `${value.toLocaleString()} Records`;
        //     }
        //     defaultTooltipContent += `<span class="tooltip-series"><span class="text-sm">${seriesName}:</span><span class="text-md font-semibold">${formattedValue}</span></span>`;
        //   });
        //   defaultTooltipContent += `</div>`;
        //   return defaultTooltipContent;
        // }
      },
    },
    legend: {
      position: "bottom", // Move the legend to the bottom
      horizontalAlign: "center", // Center align the legend
      offsetY: 10,
      itemMargin: {
          horizontal: 5,
          vertical: 5
      },
      // customLegendItems: legends.map((set) => {
      //   return set.Label
      // })
      formatter: function (seriesName, opts) {
        // return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
        // console.log('seriesName', seriesName, opts, legends[opts.seriesIndex]?.Label)
        return [`<strong>${legends[opts.seriesIndex]?.Label || seriesName}</strong>`];
      }
    },
  };

  // Generate chartSeries
  const chartSeries = dataSets.map((set) => ({
    name: set.Label,
    type: set.ElementTag.toLowerCase(),
    data: set.Data.map((dataPoint) => dataPoint.Value),
  }));

  return { chartOptions, chartSeries };
};

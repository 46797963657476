import React from "react";
import clsx from "clsx";
// create language slector component
const LanguageSelector = ({
    currentLanguage,
    handleChangeLanguage,
    withAnimation = true,
}) => {
    const isArabic = currentLanguage === "ar";
    const isEnglish = currentLanguage === "en";
    return (
      <div className="text-white text-center w-full ">
        
        <button
          type="button"
          className="cursor-pointer pb-2"
          onClick={handleChangeLanguage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            direction="ltr"
            className="group transition-transform duration-500"
            key={`lang_switcher_${currentLanguage}`}
          >
            <image
              x="5"
              y="15"
              width="20"
              height="20"
              href={require("@assets/uk-flag.png")}
              className={clsx(
                "transition-all origin-left duration-500",
                !isEnglish
                  ? withAnimation ? "group-hover:scale-150 group-hover:filter-none grayscale" : "grayscale"
                  : withAnimation ? "group-hover:grayscale  group-hover:scale-75" : ""
              )}
              fontFamily="Arial, sans-serif"
              fontSize={isEnglish ? "26" : "22"}
              fontWeight={isEnglish ? "800" : "400"}
              textAnchor="middle"
            />

            <image
              x="37"
              y="30"
              width="20"
              height="20"
              href={require("@assets/saudi-flag.png")}
              className={clsx(
                "transition-all origin-right duration-500",
                !isArabic
                  ? withAnimation ? "group-hover:scale-150 group-hover:filter-none grayscale" : "grayscale"
                  : withAnimation ? "group-hover:grayscale group-hover:scale-75" : ""
              )}
              fontFamily="Arial, sans-serif"
              fontSize={isArabic ? "28" : "22"}
              fontWeight={isArabic ? "800" : "400"}
              textAnchor="middle"
            />
          </svg>

        
        </button>
      </div>
    );
}
export default LanguageSelector;

import React, { useEffect, useState } from "react";
import {
  getReconciliationSummary,
  GetSFMDisplay,
  isSfmSuccess,
  FormatWithCommas
} from "@helpers/helpers";
import { useTranslation } from "react-i18next";
import SfmModalStore from "@stores/SfmModalStore";
import Loader from "@components/LoaderComponent/loader";
import ButtonComponent from "@components/ButtonComponent";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

const ReconciliationSummary = ({
  ReconciliationSummaryTitle,
  ReconciliationSummaryTag,
  redirectionURL,
  headers,
}) => {
  const [reconciliationSummary, setReconciliationSummary] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [streamMapping, setStreamMapping] = useState({});
  const [uniqueStreamTags, setUniqueStreamTags] = useState([]);

  const location = useLocation();
  const { t: translate } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    getReconciliationSummary(ReconciliationSummaryTag, (data) => {
      if (data?.ReconciliationSummary) {
        setReconciliationSummary(data.ReconciliationSummary);
      } else {
        const sfmData = GetSFMDisplay(data.SFM);
        if (isSfmSuccess(sfmData)) {
          window.location.href = redirectionURL;
        } else {
          SfmModalStore.open(sfmData);
        }
      }
    });
  }, [location, ReconciliationSummaryTag]);

  useEffect(() => {
    const newStreamMapping = {};
    if (Object.keys(reconciliationSummary).length > 0) {
      reconciliationSummary.forEach((item) => {
        item.Labels.forEach((label) => {
          label.ReconciliationStreams.forEach((stream) => {
            if (!newStreamMapping[stream.ReconciliationStreamTag]) {
              newStreamMapping[stream.ReconciliationStreamTag] = {
                name: stream.ReconciliationStreamName,
                labels: {},
              };
            }
            newStreamMapping[stream.ReconciliationStreamTag][label.LabelTag] = {
              count:
                stream.Attributes?.find((attr) => attr.Key === "Count")
                  ?.Value || 0,
              amount:
                stream.Attributes?.find((attr) => attr.Key === "Amount")
                  ?.Value || 0,
            };
          });
        });
      });
      const newUniqueStreamTags = Object.keys(newStreamMapping);
      setStreamMapping(newStreamMapping);
      setUniqueStreamTags(newUniqueStreamTags);
      setIsLoading(false);
    }
  }, [reconciliationSummary]);

  return isLoading ? (
    <div className="fixed top-0 left-0 w-screen h-dvh bg-black opacity-50 flex justify-center items-center z-50">
      <Loader dots/>
    </div>
  ) : (
    <div className="flex flex-col justify-around items-center w-full container h-[90vh] m-auto max-w-[90vw] overflow-x-auto">
      <div className=" my-5 mx-auto max-w-[90vw] w-full flex justify-between ">
        <h1 className="text-2xl font-normal rtl:text-right">
          {ReconciliationSummaryTitle}
        </h1>
        <ButtonComponent
          color="blue"
          id="view-all"
          onClick={() => (window.location.href = `${redirectionURL}?viewAll=true`)}
          ref={null}
          icon={null}
          text={translate("shared.viewAll")}
        />
      </div>
      <table
        className="w-full border-separate bg-gray-50 px-[50px] rounded"
        style={{ borderSpacing: "0px 20px" }}
      >
        <thead>
          <tr className="text-15 border-b-2 border-line text-left rtl:text-right">
            <th className="px-[20px]">Reconciliation Stream</th>
            {headers.map((header, index) => (
              <th key={index} className={clsx(header.className, "px-[20px]")}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {uniqueStreamTags.map((tag, index) => {
            let totalCount = 0;
            return (
              <tr
                key={index}
                className="bg-white hover:bg-gray-100 h-[102px] rounded-[10px]"
              >
                <td className="px-[20px]">
                  <div>{streamMapping[tag].name}</div>
                </td>
                {headers.map((header, headerIndex) => {
                  const values = streamMapping[tag][header.mapping] || {
                    count: 0,
                    amount: 0,
                  };
                  totalCount += parseFloat(values.count);
                  const countNotNull = parseFloat(values.count) !== 0;
                  return (
                    <td
                      key={headerIndex}
                      className={clsx(header.className, "px-[20px]")}
                    >
                      <div
                        className={clsx(
                          countNotNull && "cursor-pointer",
                          "flex flex-col gap-[8px] w-[fit-content]"
                        )}
                        onClick={() => {
                          if (countNotNull) {
                            window.location.href = `${redirectionURL}?ReconciliationStreamTag=${tag}${
                              header.redirectionFilter && countNotNull
                                ? header.redirectionFilter
                                : ""
                            }`;
                          }
                        }}
                      >
                        <span>{FormatWithCommas(parseFloat(values.count))}</span>
                        {values.amount !== 0 && (
                          <span
                            className={clsx(
                              header.className,
                              header?.amountClassName
                            )}
                          >
                            {values.amount}
                          </span>
                        )}
                      </div>
                    </td>
                  );
                })}
                <td
                  className={clsx(
                    totalCount !== 0 ? "cursor-pointer" : "cursor-not-allowed",
                    `px-[20px] rounded-r-[8px]`
                  )}
                  onClick={() => {
                    if (totalCount !== 0) {
                      window.location.href = `${redirectionURL}?ReconciliationStreamTag=${tag}`;
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default ReconciliationSummary;

import React from "react";
import Loader from "@components/LoaderComponent/loader";
import { FormatWithCommas } from "@helpers/helpers";
import clsx from "clsx";

const DEFAULT_Z_INDEX = 20;

const PopupComponent = ({
  externalClassName,
  open,
  cellData,
  value,
  setPopupOpen,
  rawValue,
  addBlur = false,
  hideCellValue,
  popupLoading = false,
  z = DEFAULT_Z_INDEX,
  innerHtml,
}) => {
  return popupLoading ? (
    <div className="fixed top-0 left-0 w-screen h-dvh bg-black opacity-50 flex justify-center items-center z-50">
      <Loader dots />
    </div>
  ) : open ? (
    <div
      className={`fixed inset-0 flex items-center justify-center cursor-default`}
      style={{
        zIndex: z,
      }}
    >
      <div
        className={clsx(
          `absolute w-screen h-dvh bg-gray-800 bg-opacity-75 z-[-1]`,
          addBlur && "backdrop-filter backdrop-blur-sm"
        )}
        onClick={() => {
          setPopupOpen && setPopupOpen(false);
        }}
      ></div>

      <div
        className={clsx(
          `relative bg-white max-w-[95vw] max-h-[90vh] p-5 px-8 rounded shadow-lg z-[20] overflow-y-auto`,
          cellData?.column?.columnDef?.popupClassName,
          !!externalClassName && externalClassName
        )}
      >
        <svg
          onClick={() => {
            setPopupOpen && setPopupOpen(false);
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="absolute cursor-pointer top-2 right-2 text-gray-600 hover:text-red-500 hover:scale-125 transition-transform duration-500 h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <div className="mt-5">
          {innerHtml ? (
            <div dangerouslySetInnerHTML={{ __html: innerHtml }}></div>
          ) : rawValue ? (
            rawValue
          ) : (
            cellData.column.columnDef.popupContent(cellData.row.original)
          )}
        </div>
      </div>
    </div>
  ) : (
    !hideCellValue && (
      <span
        className="cursor-pointer underline underline-offset-4"
        onClick={() => {
          setPopupOpen && setPopupOpen(cellData.cell.id);
        }}
      >
        {rawValue
          ? rawValue
          : cellData.column.columnDef.popupCellDisplay(
              cellData.column.columnDef.showThousandSeperator
                ? FormatWithCommas(value)
                : value
            )}
      </span>
    )
  );
};

export default PopupComponent;

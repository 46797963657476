import { makeObservable, observable, action } from "mobx";
class GlobalStore {
  timeZone = "Asia/Riyadh";
  ttpTenantCode = "2222";
  ttpUserId = "";
  languageCode = "en";

  constructor() {
    makeObservable(this, {
      timeZone: observable,
      ttpTenantCode: observable,
      ttpUserId: observable,
      languageCode: observable,
      setGlobalSettings: action,
    });

    const storedTtpUserId = localStorage.getItem('ttpUserId');
    if (storedTtpUserId) {
      this.ttpUserId = storedTtpUserId;
    }
  }

  isLoggedIn() {
    return this.ttpUserId !== "";
  }

  setGlobalSettings({
    timeZone = "",
    ttpTenantCode = "",
    ttpUserId = "",
    languageCode = "",
    callback = null,
  }) {
    this.timeZone = timeZone || this.timeZone;
    this.ttpTenantCode = ttpTenantCode || this.ttpTenantCode;
    this.ttpUserId = ttpUserId || this.ttpUserId;
    this.languageCode = languageCode || this.languageCode;

    if (ttpUserId) {
      localStorage.setItem('ttpUserId', this.ttpUserId);
    }
    callback && callback();
  }
}

const myStore = new GlobalStore();

export default myStore;

import ListingStore from "@stores/ListingStore";
import { apiHelper } from "@helpers/apiHelper";
import SfmModalStore from "@stores/SfmModalStore";
import { GetSFMDisplay, isSfmSuccess } from "@helpers/helpers";

const STORE_LIMIT = 100;
class Store extends ListingStore {
  microserviceInput = "";
  dataKey = "DataAcquisitionInstances";
  limit = STORE_LIMIT;
  offset = 0;
  showMore = true;
  setMicroservice(microservice) {
    this.microserviceInput = microservice;
  }

  async getData() {
    const externalurl = `/api/v1/DataAcquisition/GetDataAcquisitionInstances`;
    const activityTag = "GetDataAcquisitionInstances";

    const apiRequest = apiHelper({
      externalurl,
      activityTag,
      filter: {
        enabled: true,
        value: this.filter,
      },
      sort: {
        enabled: true,
        value: this.sort,
      },
      limit: this.limit,
      offset: this.offset,
    });

    return apiRequest
      .then((data) => {
        const sfmData = GetSFMDisplay(data.SFM);
        if (!isSfmSuccess(sfmData)) {
          SfmModalStore.open(sfmData);
          return {
            data: [],
          };
        } else {
          return {
            data: data.DataAcquisitionInstances,
          };
          // return {
          //   data: data.DataAcquisitionInstances,
          //   total: data.DataAcquisitionInstances.length, // You might need to adjust this depending on the actual response structure
          //   page: 1, // Adjust if pagination data is available in the response
          // };
        }
      })

      .catch((error) => {
        return;
      });
  }
}

const myStore = new Store();

export default myStore;

import React, { useEffect, useState } from "react";
import TitleComponent from "@components/TitleComponent";
import CustomTable from "@components/CustomTable";
import { CopyToCliboard } from "@components/CustomTable/helpers";
import ButtonComponent from "@components/ButtonComponent";
import { CommitManualMatching } from "@helpers/helpers";
import { GetSFMDisplay } from "@helpers/helpers";
import SfmModalStore from "@stores/SfmModalStore";
import Confirmationpopup from "@components/ConfirmationPopup";
import { fetchDynamicTags, FormatWithCommas } from "@helpers/helpers";
import Dropdown from "@components/FilterComponent/components/DropDownComponent";
import clsx from "clsx";
import Loader from "@components/LoaderComponent/loader";

const CorrectiveActionComponent = ({
  correctiveAction,
  setPopupOpen,
  MatchingRequestId,
  resetComponent,
  translate,
}) => {
  const filterValues = {
    values: [
      {
        value: "MANUALLY_CLEARED",
        displayName: translate("shared.manuallyCleared"),
        className: "text-green-600 text-semibold",
      },
      {
        value: "RESULTANT_EXCEPTION",
        displayName: translate("shared.resultantException"),
        className: "text-orange-600 text-semibold",
      },
    ],
  };

  const defaultValues = () => {
    return correctiveAction.map((singleAction) => {
      return {
        value: singleAction?.CorrectionResultTag,
        displayName: singleAction?.CorrectionResultName,
        className:
          filterValues.values.find(
            (option) => option.value === singleAction?.CorrectionResultTag
          )?.className || "text-green-400",
      };
    });
  };

  const [externalOpenConfirmationAction, setExternalOpenConfirmationAction] =
    useState(false);
  const [dynamicTags, setDynamicTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCorrectiveReason, setSelectedCorrectiveReason] = useState(
    defaultValues() || []
  );

  const columns = [
    {
      accessorKey: "ReconciliationStreamName",
      header: "Reconciliation Stream",
      size: 230,
      truncate: true,
    },
    {
      accessorKey: "DataAcquisitionName",
      header: "Data Source",
      size: 150,
    },
    {
      accessorKey: "AreaName",
      header: "Area",
      size: 160,
    },
    {
      accessorKey: "OutletName",
      header: "Outlet",
      size: 160,
    },
    {
      accessorKey: "TransactionTerminalCode",
      header: "Terminal",
      size: 150,
    },
    {
      accessorKey: "Amount",
      header: "Amount",
      size: 150,
      sortable: true,
      processFilterValue: (value, row) => {
        return row.Amount.toString();
      },
      cell: ({ row }) => {
        return (
          <span className="flex flex-col items-center justify-center group font-semibold">
            <span>{`${row.original.Currency} ${FormatWithCommas(
              row.original.Amount
            )}`}</span>
          </span>
        );
      },
      filterType: "amount",
      operator: "EQ",
    },
    {
      accessorKey: "RecordReference",
      header: "Record Reference",
      size: 150,
    },
    {
      accessorKey: "RecordDate",
      header: "Record Date",
      size: 180,
      splitCellValue: "T",
    },
    {
      accessorKey: "RecordType",
      header: "Record Type",
      size: 150,
    },
    {
      accessorKey: "Attributes",
      header: "Record Attributes",
      size: 150,
      displayInPopup: true,
      popupCellDisplay: (value) => {
        return (
          <div className="relative text-indigo-400 font-semibold">
            ({value.length})
          </div>
        );
      },
      popupContent: (row) => {
        const value = row.Attributes;
        return (
          <div className="relative ">
            <div className="flex flex-col min-w-[400px] transition-all bg-primary-dark text-[#777f9e] text-14 rounded-lg px-[2px] py-[8px] mb-3 opacity-100">
              {value &&
                value.map((attr, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between gap-2 mb-[2px]"
                  >
                    <span className="py-[2px] whitespace-nowrap text-xs font-semibold">
                      {attr.Key}
                    </span>
                    <span className="relative py-[2px] flex gap-2 items-center px-[8px] whitespace-nowrap ltr:text-right rtl:text-left text-sm">
                      {attr.Value}
                      <div className=" absolute left-0 w-full justify-center flex opacity-0 hover:opacity-100 bg-gray-300 bg-opacity-50 rounded">
                        <CopyToCliboard size="5" value={attr.Value} />
                      </div>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "PaymentMethod",
      header: "Payment Method",
      size: 180,
    },
  ];
  const renderSection = (
    identifier,
    title,
    data,
    allowedKeys,
    displayValues,
    customValues
  ) => {
    return (
      <div className="section mt-10 pb-5 px-5 border-b-2 border-gray-200">
        <div
          className={`title flex gap-2 items-center cursor-pointer font-bold text-lg`}
        >
          {title}
        </div>
        <div
          className={`content px-5 grid grid-rows-[0fr] transition-all ease-in-out duration-500 `}
        >
          <div className={clsx("grid", data && "grid-cols-4 gap-6")}>
            {!!data &&
              Object.keys(data).map(
                (key, index) =>
                  allowedKeys.includes(key) && (
                    <div
                      key={index}
                      className="info flex flex-col gap-3 items-start"
                    >
                      <div className="key font-semibold">
                        {displayValues[key] || key}
                      </div>
                      <div className="value">
                        {!!customValues[key] ? customValues[key] : data[key]}
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    );
  };
  const renderTables = (singleAction, correctiveActionCount) => {
    const sourceData = [];
    const destinationData = [];

    const record = singleAction;

    if (record.SourceRecords.length > 0) {
      record.SourceRecords.forEach((sourceRecord, index) => {
        sourceData.push(sourceRecord);
      });
    }
    if (record.DestinationRecords.length > 0) {
      record.DestinationRecords.forEach((destinationRecord, index) => {
        destinationData.push(destinationRecord);
      });
    }

    const destinationDataAmount = destinationData.reduce((acc, record) => {
      return acc + record.Amount;
    }, 0);
    const sourceDataAmount = sourceData.reduce((acc, record) => {
      return acc + record.Amount;
    }, 0);
    return (
      <>
        <div className="p-5 pl-10 w-full flex flex-col gap-2">
          {destinationData?.length > 0 && (
            <CustomTable
              externalData={destinationData}
              columnsWithoutPin={columns}
              loading={false}
              pinCondition={(row) => {
                return false;
              }}
              allowRowPinning={false}
              pinningInitialState={[""]}
              setParentSelectedValues={() => {}}
              prefix="ExceptionAtHandRecords"
              title={`${destinationData.length} Destination Record${
                destinationData.length > 1 ||
                destinationData.length === 0 ||
                !destinationData
                  ? "s"
                  : ""
              } ${
                destinationDataAmount
                  ? ` - ${destinationDataAmount} ${correctiveAction[0].Currency}`
                  : ""
              }`}
              pinnedRowHeight={62}
              maxTableHeight={400}
              showFilter={false}
              minimalTable={true}
              hideActionColumn={true}
              hidePinColumn={true}
              isExpandable={true}
              initialTableVisibility={correctiveActionCount > 1 ? false : true}
            />
          )}
          {sourceData?.length > 0 && (
            <CustomTable
              externalData={sourceData}
              columnsWithoutPin={columns}
              loading={false}
              pinCondition={(row) => {
                return false;
              }}
              allowRowPinning={false}
              pinningInitialState={[""]}
              setParentSelectedValues={() => {}}
              prefix="SourceRecords"
              title={`${sourceData.length} Source Record${
                sourceData.length > 1 || sourceData.length === 0 || !sourceData
                  ? "s"
                  : ""
              } ${
                sourceDataAmount
                  ? ` - ${sourceDataAmount} ${correctiveAction[0].Currency}`
                  : ""
              }`}
              pinnedRowHeight={62}
              maxTableHeight={400}
              showFilter={false}
              minimalTable={true}
              hidePinColumn={true}
              hideActionColumn={true}
              isExpandable={true}
              initialTableVisibility={correctiveActionCount > 1 ? false : true}
            />
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    fetchDynamicTags("Lists", ["CORRECTIVE_ACTION_REASONS"], (newTags) => {
      setDynamicTags(newTags);
    });
  }, []);

  const updatedCorrectiveActions = correctiveAction.map((action, index) => ({
    CorrectionResultId: action?.CorrectionResultId,
    CorrectionResultName: selectedCorrectiveReason[index]?.CorrectionResultName,
    CorrectionResultTag: selectedCorrectiveReason[index]?.CorrectionResultTag,
  }));

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center z-50">
          <Loader dots/>
        </div>
      )}
      <div className="mt-10 flex justify-between items-center">
        <TitleComponent
          text={translate("corrective-action.CorrectivePreview")}
          size="xl"
        />
        <div className="flex justify-between items-center">
          <ButtonComponent
            className="text-sm min-w-max h-fitp-2 rounded-lg px-4 cursor-pointer"
            withoutBorder={true}
            id="action-cancel"
            color="red"
            onClick={() => {
              setPopupOpen(false);
              // resetComponent();
            }}
            ref={null}
            icon={null}
            text={translate("shared.popup-actions.cancel")}
          />
          <ButtonComponent
            className="text-sm min-w-max h-fitp-2 rounded-lg px-4 cursor-pointer"
            withoutBorder={true}
            id="action-proceed"
            color="green"
            onClick={() => setExternalOpenConfirmationAction(true)}
            ref={null}
            icon={null}
            text={translate("shared.popup-actions.proceed")}
          />
        </div>
      </div>
      {correctiveAction &&
        correctiveAction.map((singleAction, index) => {
          const keyToPass = !!singleAction.ValueVariance
            ? "ValueVariance"
            : "AmountVariance";
          const variance =
            singleAction.ValueVariance || singleAction.AmountVariance;
          return (
            <div key={index} className="border-2 border-gray-200 my-5 ">
              {renderSection(
                "",
                "",
                singleAction,
                [
                  "CorrectionResultName",
                  "DestinationAmount",
                  "SourceAmount",
                  keyToPass,
                ],
                {
                  CorrectionResultName: translate(
                    "corrective-action.CorrectionResultName"
                  ),
                  DestinationAmount: translate(
                    "corrective-action.DestinationAmount"
                  ),
                  SourceAmount: translate("corrective-action.SourceAmount"),
                  AmountVariance: translate("corrective-action.Variance"),
                  ValueVariance: translate("corrective-action.Variance"),
                },
                {
                  CorrectionResultName: (
                    <span className="font-semibold">
                      <Dropdown
                        key={index}
                        filter={filterValues}
                        handleChange={(e, filter) => {
                          const selectedOption = filter.values.find(
                            (option) => option.value === e.target.value
                          );
                          const updatedCorrectiveActions = correctiveAction.map(
                            (action, i) => {
                              if (i === index) {
                                return {
                                  CorrectionResultId:
                                    action?.CorrectionResultId,
                                  CorrectionResultName:
                                    selectedOption?.displayName,
                                  CorrectionResultTag: selectedOption?.value,
                                };
                              }
                              return action;
                            }
                          );
                          setSelectedCorrectiveReason(updatedCorrectiveActions);
                        }}
                        selectedOption={filterValues.values.find(
                          (option) =>
                            option.value ===
                            selectedCorrectiveReason[index].CorrectionResultTag
                        )}
                        index={index}
                        className={filterValues.values.find(
                          (option) =>
                            option.value ===
                            selectedCorrectiveReason[index].CorrectionResultTag
                        )?.className || filterValues.values[0].className}
                      />
                    </span>
                  ),
                  DestinationAmount: `${
                    !!singleAction.DestinationAmount
                      ? `${singleAction?.DestinationAmount} ${singleAction?.Currency}`
                      : "N/A"
                  }`,
                  SourceAmount: `${
                    !!singleAction.SourceAmount
                      ? `${singleAction?.SourceAmount} ${singleAction?.Currency}`
                      : "N/A"
                  }`,
                  AmountVariance: (
                    <span
                      className={`font-semibold ${
                        variance === 0 ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      {variance} {singleAction?.Currency}
                    </span>
                  ),
                  ValueVariance: (
                    <span
                      className={`font-semibold ${
                        variance === 0 ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      {variance} {singleAction?.Currency}
                    </span>
                  ),
                }
              )}
              {renderTables(singleAction, correctiveAction?.length)}
            </div>
          );
        })}
      {externalOpenConfirmationAction && (
        <Confirmationpopup
          singleAction={{
            actionFN: (selectedReason, reasonDescription) => {
              setIsLoading(true);
              CommitManualMatching(
                MatchingRequestId,
                selectedReason,
                reasonDescription,
                updatedCorrectiveActions,
                (data) => {
                  const sfmData = GetSFMDisplay(data.SFM);
                  setIsLoading(false);
                  SfmModalStore.open(sfmData, () => {
                    window.location.href = "/exception-list";
                  });
                }
              );
            },
            confirmation: true,
            confirmationMessage:
              "Do you want to start attempt matching process? Please confirm if you wish to proceed.",
            severity: "medium",
            showReason: true,
            actionTypeTag: "MANUAL_MATCHING",
          }}
          setOpenConfirmationAction={setExternalOpenConfirmationAction}
          dynamicTags={dynamicTags}
        />
      )}
    </div>
  );
};

export default CorrectiveActionComponent;

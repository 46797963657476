import React from "react";
import { toast } from "react-toastify";
import { FormatWithCommas } from "@helpers/helpers";

const DEFAULT_SCROLL_AMOUNT = 10;

export const scrollFn = (
  selector = ".main-table-container",
  direction,
  amount = DEFAULT_SCROLL_AMOUNT
) => {
  const table = document.querySelector(selector);
  const scrollBy = amount;
  switch (direction) {
    case "up":
      // table.scrollTop = table.scrollTop - scrollBy;
      table.scrollTo({
        top: table.scrollTop - scrollBy,
        behavior: "smooth",
      });
      break;
    case "down":
      table.scrollTo({
        top: table.scrollTop + scrollBy,
        behavior: "smooth",
      });
      break;
    case "left":
      table.scrollTo({
        left: table.scrollLeft - scrollBy,
        behavior: "smooth",
      });
      break;
    case "right":
      table.scrollTo({
        left: table.scrollLeft + scrollBy,
        behavior: "smooth",
      });
      break;
    default:
      break;
  }
};
const DEFAULT_PINNED_INDEX = 1;
export const getCommonPinningStyles = (
  column,
  index = 0,
  backgroundColor,
  position = "unset",
  zindex = 0,
  pinnedZIndex = DEFAULT_PINNED_INDEX
) => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn =
    isPinned === "left" && column.getIsLastColumn("left");
  const isFirstRightPinnedColumn =
    isPinned === "right" && column.getIsFirstColumn("right");

  let leftvalue = column.getStart("left");

  let rightvalue = column.getAfter("right");

  return {
    boxShadow: isLastLeftPinnedColumn
      ? "-6px 0px 5px -1px #d6d6d6 inset"
      : isFirstRightPinnedColumn
      ? "6px 0px 5px -1px #d6d6d6 inset"
      : undefined,
    left: isPinned === "left" ? `${leftvalue}px` : undefined,
    right: isPinned === "right" ? `${rightvalue}px` : undefined,
    backgroundColor: backgroundColor || "white",
    opacity: 1,
    position: isPinned ? "sticky" : position,
    width: column.getSize(),
    zIndex: isPinned ? pinnedZIndex : zindex,
    fontWeight: isPinned ? "bold" : "normal",
  };
};

export const saveToLocalStorage = (key, dataToSave) => {
  localStorage.setItem(key, JSON.stringify(dataToSave));
};

export const getLocalConfig = (key, location, def = []) => {
  const params = new URLSearchParams(location.search);
  const viewAllParam = params?.get("viewAll");
  const savedLocalState = localStorage.getItem(key);
  return viewAllParam
    ? def
    : savedLocalState
    ? JSON.parse(savedLocalState)
    : def;
};

export const stopScroll = (scrollTimeout) => {
  if (scrollTimeout !== null) {
    clearInterval(scrollTimeout);
  }
};

export const filter = (
  column,
  value,
  row,
  appliedFilters,
  saveAndApplyFilter,
  translate
) => {
  const {
    accessorKey,
    isMultiple,
    filterCheck,
    filterKey,
    processFilterValue,
    processFilterKey,
  } = column;

  const filterKeyToUse = processFilterKey
    ? processFilterKey(value, row)
    : filterKey || accessorKey;
  let valueToUse = processFilterValue ? processFilterValue(value, row) : value;

  const newFilters = { ...appliedFilters };
  const filterType = column.filterType;
  const operator = column.operator;

  if (!!filterType && filterType === "integer") {
    valueToUse = parseInt(value);
  }

  if (!filterCheck || (filterCheck && filterCheck(valueToUse))) {
    const isDate = filterType === "date";
    const isAmount = filterType === "amount";
    if (!!filterType && (isDate || isAmount)) {
      newFilters[filterKeyToUse] = {
        filterType: filterType,
        value: `${isAmount ? valueToUse.toString() : valueToUse.split("T")[0]}`,
        operator: "GTE",
        displayName: isAmount
          ? valueToUse.toString()
          : valueToUse.split("T")[0],
      };
      newFilters[filterKeyToUse] = {
        filterType: filterType,
        value: `${isAmount ? valueToUse.toString() : valueToUse.split("T")[0]}`,
        operator: "LTE",
        displayName: isAmount
          ? valueToUse.toString()
          : valueToUse.split("T")[0],
      };
    } else if (filterType === "checkbox") {
      const valueToSend = valueToUse.toString().split(",").join("|");
      newFilters[filterKeyToUse] = {
        filterType: filterType,
        value: valueToSend,
        operator: operator,
        displayName: value,
      };
    } else {
      newFilters[filterKeyToUse] = {
        filterType: filterType,
        value: valueToUse,
        operator: operator,
        displayName: value,
      };
    }
    translate &&
      toast.info(
        translate(`shared.filter-shortcut-notification`, {
          value:
            filterType === "integer" || filterType === "amount"
              ? FormatWithCommas(value)
              : value,
        }),
        {
          hideProgressBar: false,
          autoClose: 1500,
        }
      );
    saveAndApplyFilter(newFilters);
  }
};

export const scrollHeaderIntoView = (
  headerId,
  isPinnedLeft = false,
  isRTl = false
) => {
  const buffer = 80;

  var container = document.querySelector(".main-table-container");

  // Get the target div
  var target = document.getElementById(headerId);

  var targetPopup = target.querySelector(".popup");

  // Calculate the scroll position
  var scrollPosition = target.offsetLeft - container.offsetLeft - buffer;

  // Scroll the container if the target is not in view
  if (
    scrollPosition < container.scrollLeft ||
    scrollPosition > container.scrollLeft + container.offsetWidth
  ) {
    container.scrollLeft = scrollPosition;
    container.scrollTo({
      left: scrollPosition,
      behavior: "smooth",
    });
  }

  // Check if the scroll position is past the halfway point
  if (targetPopup) {
    let left = "unset";
    let right = "unset";
    if (
      !isPinnedLeft &&
      targetPopup &&
      scrollPosition > container.offsetWidth / 2
    ) {
      // if (isRTl){
      //   right = "0"
      // } else {
      right = "0";
      // }
    } else {
      if (isRTl && scrollPosition > container.offsetWidth / 2) {
        right = "0";
      } else {
        left = "0";
      }
    }
    targetPopup.style.left = left;
    targetPopup.style.right = right;
  }
};

export const handleClearSort = (
  value,
  appliedSort,
  saveAndApplySort,
  setAppliedSort,
  setSubMenuOpen
) => {
  const newSort = [...appliedSort];
  const index = newSort.findIndex((item) => item.id === value);
  if (index !== -1) {
    newSort.splice(index, 1);
    saveAndApplySort(newSort);
    setAppliedSort(newSort);
    setSubMenuOpen(false);
  }
};

export const handleSort = (
  value,
  direction,
  appliedSort,
  saveAndApplySort,
  setAppliedSort,
  setSubMenuOpen
) => {
  const newSort = [...appliedSort];
  const idExists = newSort.some((item) => item.id === value.id);
  if (!idExists) {
    newSort.push({
      id: value.id,
      direction: direction,
      name: value.column.columnDef.header,
    });
  } else {
    const index = newSort.findIndex((item) => item.id === value.id);
    newSort[index].id = value.id;
    newSort[index].direction = direction;
  }
  saveAndApplySort(newSort);
  setAppliedSort(newSort);
  setSubMenuOpen(false);
};

export const handleHideColumn = (
  header,
  saveOrderingConfig,
  setSubMenuOpen,
  setShowResetButton
) => {
  header.column.toggleVisibility(false);
  saveOrderingConfig(header.id, false);
  setSubMenuOpen(null);
  setShowResetButton(true);
};

export const CopyToCliboard = ({ value, displayText, size = "6" }) => {
  return (
    <div title="Copy">
      <svg
        onClick={() => {
          navigator.clipboard.writeText(value);
          toast.info(`"${displayText || value}" copied to clipboard`, {
            hideProgressBar: false,
            autoClose: 1500,
          });
        }}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        xlinkTitle="Copy"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`size-${size} hover:scale-110 hover:text-indigo-800 transition-all duration-200`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
        />
      </svg>
    </div>
  );
};

export const checkFullScreen = () => {
  const isFullScreen =
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement;
  return !!isFullScreen;
};

export const openFullScreen = () => {
  var elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
};
export const closeFullScreen = () => {
  // Check if the document is currently in fullscreen mode
  const isFullScreen =
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement;

  // Only attempt to exit fullscreen mode if the document is in fullscreen mode
  if (isFullScreen) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
};

export const setHTMLFontSize = (
  action = "increase",
  setCurrentFontSize,
  currentFontSize,
  DEFAULT_FONT_SIZE,
  MAX_FONT_SIZE,
  MIN_FONT_SIZE
) => {
  const STEP = 2;

  const htmlElement = document.documentElement;
  let currentFontSizeValue =
    currentFontSize ||
    parseInt(htmlElement.style.fontSize, 10) ||
    DEFAULT_FONT_SIZE;

  if (action === "increase") {
    currentFontSizeValue = Math.min(currentFontSizeValue + STEP, MAX_FONT_SIZE);
  } else if (action === "decrease") {
    currentFontSizeValue = Math.max(currentFontSizeValue - STEP, MIN_FONT_SIZE);
  } else if (action === "default") {
    currentFontSizeValue = DEFAULT_FONT_SIZE;
  }
  setCurrentFontSize(currentFontSizeValue);

  htmlElement.style.fontSize = `${currentFontSizeValue}px`;
};

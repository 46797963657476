import React, { useState } from "react";
import { transformData } from "@helpers/helpers";
import { useTranslation } from "react-i18next";
import Dropdown from "@components/FilterComponent/components/DropDownComponent";
import Loader from "@components/LoaderComponent/loader";
import Lottie from "lottie-react";
import low from "@assets/lottie/low.json";
import medium from "@assets/lottie/medium.json";
import high from "@assets/lottie/high.json";
import ButtonComponent from "@components/ButtonComponent";

const Confirmationpopup = ({
  singleAction = { showReason: false },
  prepareApiData = { action: { showReason: false }, row: {}, rowIDs: [] },
  setOpenConfirmationAction,
  setPrepareApiData,
  dynamicTags,
  store,
  afterClose,
}) => {
  const { action, row, rowIDs } = prepareApiData;
  const [selectedReason, setSelectedReason] = useState("");
  const [reasonDescription, setReasonDescription] = useState("");
  const { t: translate } = useTranslation();

  const getDynamicTags = (key, onlyChildren = false) => {
    return transformData(
      (!!dynamicTags &&
        dynamicTags.length > 0 &&
        dynamicTags
          .find((group) => group.Tag === key)
          ?.Items.filter((item) =>
            item.Tags.includes(
              action.actionTypeTag || singleAction.actionTypeTag
            )
          )) ||
        [],
      onlyChildren
    );
  };

  const handleCancel = () => {
    setOpenConfirmationAction(false);
    !singleAction && setPrepareApiData({});
    if (singleAction.cancelAction) {
      singleAction.cancelAction();
    }
    if (afterClose) {
      afterClose();
    }
  };

  const confirmationMessage =
    action?.confirmationMessage || singleAction?.confirmationMessage;

  const handleProcceed = () => {
    if (singleAction.actionFN) {
      singleAction.actionFN(selectedReason, reasonDescription);
    } else {
      if (rowIDs) {
        if (store) {
          store.loading = true;
        }
        action.actionFN(null, rowIDs, selectedReason, reasonDescription);
      } else {
        if (store) {
          store.loading = true;
        }
        action.actionFN(row, null, selectedReason, reasonDescription);
      }
    }
    setOpenConfirmationAction(false);
  };

  const animations = {
    low,
    medium,
    high,
  };

  const showReason = action?.showReason || singleAction?.showReason;

  const defaultMessage = "Are you sure you want to proceed?";

  return (
    <div>
      <div className="fixed backdrop-filter backdrop-blur-sm top-0 left-0 w-full h-full bg-[#000000] bg-opacity-20 z-[55]" />
      <div className="flex items-center justify-around overflow-auto">
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-around w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 z-[56]">
          {!singleAction && Boolean(dynamicTags?.length) === false ? (
            <div className="relative w-full h-full top-0 left-0 p-5 bg-black bg-opacity-50 rounded flex justify-center items-center z-50">
              <Loader />
            </div>
          ) : (
            <div className="py-14 px-14 flex flex-col gap-6">
              <div className="m-auto">
                <Lottie
                  animationData={
                    animations[singleAction?.severity] ||
                    animations[action?.severity] ||
                    medium
                  }
                  loop={false}
                  style={{ width: "200px", height: "200px" }}
                />
              </div>
              <div className="text-xl mb-3 max-w-[400px] text-center break-words">
                {confirmationMessage || defaultMessage}
              </div>
              {showReason && (
                <>
                  <div>
                    <Dropdown
                      filter={{
                        values: getDynamicTags(
                          "CORRECTIVE_ACTION_REASONS",
                          true
                        ),
                        key: "CORRECTIVE_ACTION_REASONS",
                        title: "Reasons",
                      }}
                      defaultValue="Select Reason"
                      handleChange={(e) => {
                        setSelectedReason(e.target.value);
                      }}
                      selectedOption={dynamicTags
                        .find(
                          (group) => group.Tag === "CORRECTIVE_ACTION_REASONS"
                        )
                        ?.Items.map((item) => {
                          return { value: item.Value, displayName: item.Label };
                        })
                        .find((item) => item.value === selectedReason)}
                      index={0}
                    />
                  </div>
                  <div>
                    <span className="flex text-sm mb-1 text-gray-500 font-semibold ">
                      Description
                    </span>
                    <textarea
                      className="w-full resize-none rounded border-2 px-3 py-3 border-gray-300"
                      name="message"
                      rows="3"
                      value={reasonDescription}
                      onChange={(e) => {
                        setReasonDescription(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </>
              )}
              <div className="flex gap-2 px-4 justify-around">
                <ButtonComponent
                  type="cancel"
                  onClick={() => handleCancel()}
                  text={translate("shared.popup-actions.cancel")}
                />
                <ButtonComponent
                  disabled={
                    showReason ? !selectedReason || !reasonDescription : false
                  }
                  color="green"
                  onClick={() => handleProcceed()}
                  text={translate("shared.popup-actions.proceed")}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmationpopup;

import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoModalStore from "@stores/InfoModalStore";

import Sidebar from "@components/SideBar";
import "./App.css";
import ReconciliationRunsComponent from "@components/ReconciliationRunComponent";
import ReconciliationStreamsComponent from "@components/ReconciliationStreamsComponent";
import DataAcquisitions from "@components/DataAcquisitionsComponent";
import DataAcquisitionsInstances from "@components/DataAcquisitionsInstancesComponent";
import InTransitListComponent from "@components/InTransitListComponent";
import ExceptionsRecordComponent from "@components/ExceptionsRecordComponent";
import Welcome from "@components/Welcome";
import SfmModal from "@modals/SfmModal";
import InfoModal from "@modals/InfoModal";
import DownloadModal from "@modals/DownloadModal";
import AttemptDetails from "@components/AttemptDetails";
import RunMatchingRecords from "@components/RunMatchingRecords";
import AttemptMatching from "@components/AttemptMatching";
import NewMatching from "@components/NewMatching";
import GlobalStore from "@stores/GlobalStore";
import ReconciliationSummary from "@components/ReconciliationSummaryComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clsx from "clsx";
import Menus from "./Menus";
import LoginPage from "@components/Login/LoginPage";
import ChartComponent from "@components/ChartComponent";
import CustomChartComponent from "@components/CustomChartComponent";

const usePageChange = (callback) => {
  const location = useLocation();

  useEffect(() => {
    callback(location);
  }, [location, callback]);
};

const AppContent = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!GlobalStore.isLoggedIn()) {
      location.pathname !== "/login" &&
        localStorage.setItem("lastLocation", location.pathname);
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";
    GlobalStore.setGlobalSettings({
      languageCode: i18n.language,
    });
  });

  const handlePageChange = (location) => {
    InfoModalStore.showing = false;
  };

  const isLoginPage = location.pathname === "/login";

  usePageChange(handlePageChange);
  return (
    <div className="flex flex-col relative overflow-x-hidden bg-gray-100 bg-opacity-40 min-h-screen">
      <PageChangeHandler onPageChange={handlePageChange} />
      {!isLoginPage && <Sidebar menus={Menus} />}
      <div
        className={clsx(
          "pl-[100px] rtl:pl-0 rtl:pr-[100px] py-5",
          isLoginPage && "!pl-0 !pr-0 !py-0"
        )}
      >
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/reconciliation-runs"
            element={<ReconciliationRunsComponent />}
          />
          <Route
            path="/reconciliation-streams"
            element={<ReconciliationStreamsComponent />}
          />
          <Route path="/data-acquisitions" element={<DataAcquisitions />} />
          <Route
            path="/data-acquisitions-instances"
            element={<DataAcquisitionsInstances />}
          />
          <Route
            path="/in-transit-summary"
            element={
              <ReconciliationSummary
                ReconciliationSummaryTitle="In-Transit Summary"
                ReconciliationSummaryTag="INTRANSIT"
                redirectionURL="/in-transit-list"
                headers={[
                  {
                    label: "Total Records",
                    mapping: "INTRANSIT_RECORDS",
                    amountClassName:
                      "text-14 bg-gray-100 p-[5px] rounded-[5px]",
                  },
                  {
                    label: "Critical Records",
                    mapping: "INTRANSIT_CRITICAL",
                    className: "text-red-400",
                    amountClassName: "text-14 bg-red-50 p-[5px] rounded-[5px]",
                    redirectionFilter: "&IsInTransitCritical=true",
                  },
                ]}
                wordToAdd="critical"
              />
            }
          />
          <Route path="/in-transit-list" element={<InTransitListComponent />} />
          <Route
            path="/exception-summary"
            element={
              <ReconciliationSummary
                ReconciliationSummaryTitle="Exception Summary"
                ReconciliationSummaryTag="EXCEPTION"
                redirectionURL="/exception-list"
                headers={[
                  {
                    label: "Total Records",
                    mapping: "EXCEPTIONS_RECORDS",
                    amountClassName:
                      "text-14 bg-gray-100 p-[5px] rounded-[5px]",
                  },
                  {
                    label: "Aging Exceptions",
                    mapping: "AGING_EXCEPTIONS",
                    className: "text-red-400",
                    amountClassName: "text-14 bg-red-50 p-[5px] rounded-[5px]",
                    redirectionFilter:
                      "&AgingTierTag=EXCEPTION_LOW_AGING_THRESHOLD_IN_HOURS,EXCEPTION_MODERATE_AGING_THRESHOLD_IN_HOURS,EXCEPTION_CRITICAL_AGING_THRESHOLD_IN_HOURS",
                  },
                ]}
                wordToAdd="aging"
              />
            }
          />
          <Route
            path="/exception-list"
            element={<ExceptionsRecordComponent />}
          />
          <Route
            path="/exception-attempt-details"
            element={
              <AttemptDetails
                sentActivityTag="GetExceptionAttempts"
                from="exception"
              />
            }
          />
          <Route
            path="/inTransit-attempt-details"
            element={
              <AttemptDetails
                sentActivityTag="GetInTransitAttempts"
                from="transit"
              />
            }
          />
          <Route
            path="/run-matching-records"
            element={<RunMatchingRecords />}
          />
          <Route
            path="/exception-attempt-matching"
            element={<AttemptMatching />}
          />
          <Route
            path="/new-matching"
            element={<NewMatching />}
          />
          <Route path="/stats" element={<ChartComponent title="Business Closure Overview" />} /> 
          <Route path="/chart-test" element={<CustomChartComponent title="" />} /> 
        </Routes>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={i18n.language === "ar"}
        pauseOnFocusLoss
        pauseOnHover
      />
      <SfmModal />
      <InfoModal />
      <DownloadModal />
    </div>
  );
};
const App = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const PageChangeHandler = ({ onPageChange }) => {
  usePageChange(onPageChange);
  return null;
};

export default App;

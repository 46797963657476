import React, { useEffect } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { useTranslation } from "react-i18next";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable,
} from "@dnd-kit/sortable";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ButtonComponent from "@components/ButtonComponent";
import clsx from "clsx";
import {helperTitle} from "@helpers/helpers"

import { CSS } from "@dnd-kit/utilities";

const FOCUS_TIMEOUT_VALUE = 300;

const OrderingComponent = ({
  table,
  showResetButton,
  setShowResetButton,
  setColumnOrder,
  columnOrder,
  resetColumnOrder,
  saveOrderingConfig,
  columnVisibilityKey,
  openFilter,
  setOpenFilter,
}) => {
  const {
    t: translate,
  } = useTranslation();

  const handleReset = () => {
    resetColumnOrder();
    setOpenFilter(false);
    setShowResetButton(false);
    localStorage.setItem(columnVisibilityKey, JSON.stringify({}));
    setTimeout(() => {
      mainButtonRef.current.focus();
    }, FOCUS_TIMEOUT_VALUE);
  };

  const handleToggleAll = () => {
    const currentColumnsVisibility = table.getIsAllColumnsVisible();
    table.toggleAllColumnsVisible();
    const columnVisibility = table.getAllLeafColumns().reduce((acc, column) => {
      const isPinned = column.getIsPinned ? column.getIsPinned() : false;
      acc[column.id] = isPinned ? true : !currentColumnsVisibility;
      return acc;
    }, {});
    saveOrderingConfig(null, columnVisibility);
  };

  const handleClickOutside = () => {
    if (openFilter) {
      setTimeout(() => {
        mainButtonRef.current.focus();
      }, FOCUS_TIMEOUT_VALUE);
    }
    setOpenFilter(false);
  };

  useEffect(() => {
    if (openFilter) {
      setTimeout(() => {
        mainButtonRef.current.focus();
      }, FOCUS_TIMEOUT_VALUE);
    }
  }, [openFilter]);

  const onKeyEvent = (key) => {
    if (key === "esc") {
      handleClickOutside();
    }
    if (key === "r") {
      handleReset();
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const columns = columnOrder;
      const oldIndex = columns.findIndex((column) => column === active.id);
      const newIndex = columns.findIndex((column) => column === over.id);
      const newColumns = arrayMove(columns, oldIndex, newIndex);
      setColumnOrder(newColumns);
      setShowResetButton(true);
    }
  };

  const SortableColumn = ({ id, header, column }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const isVisible = column.getIsVisible();

    return (
      <div className="flex gap-2 items-center mb-1 mt-1">
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>
        <div
          style={style}
          {...attributes}
          className={clsx(
            `px-1 py-1 aria-pressed:cursor-grab aria-pressed:font-semibold`,
            !isVisible && "line-through"
          )}
        >
          <label className="flex gap-2 items-center">
            <input
              type="checkbox"
              checked={isVisible}
              onChange={(e) => {
                const toggleVisibility = column.getToggleVisibilityHandler();
                toggleVisibility(e);
                saveOrderingConfig(id, !isVisible);
              }}
              className="form-checkbox text-indigo-500 h-4 w-4 cursor-pointer"
            />{" "}
            <span className="cursor-pointer text-sm">{header}</span>
          </label>
        </div>
      </div>
    );
  };

  const hiddenColumns =
    table.getAllLeafColumns().length - table.getVisibleLeafColumns().length;
  const mainButtonRef = React.useRef(null);
  return (
    <KeyboardEventHandler
      handleKeys={["esc", "y", "n", "r", "enter", "+", "shift+="]}
      onKeyEvent={(key) => onKeyEvent(key)}
    >
      {openFilter && (
        <div
          className="fixed top-0 left-0 w-full h-dvh bg-black opacity-25 z-[8]"
          onClick={handleClickOutside}
        />
      )}
      <div
        className={clsx(
          `relative  inline-flex gap-5 text-left text-black w-max`,
          openFilter ? "z-[9]" : "z-[2]"
        )}
      >
        <div>
          <ButtonComponent
            isactive={openFilter}
            color="blue"
            id="columns-configuration"
            onClick={() => {
              openFilter ? handleClickOutside() : setOpenFilter(true);
            }}
            ref={mainButtonRef}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125Z"
                />
              </svg>
            }
            text={
              <>
                {helperTitle(translate('shared.columns-configuration'))}
                {hiddenColumns > 0 && (
                  <span className="text-red-400 text-xs font-normal">
                    ({hiddenColumns})
                  </span>
                )}
              </>
            }
          />
        </div>
        {openFilter && (
          <div className=" absolute ltr:right-0 rtl:left-0 mt-10 w-max  shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-[1000]">
            <div className="flex flex-row divide-gray-100 ">
              <div className="flex flex-col gap-4 w-max justify-between bg-gray-50 p-5 rounded">
                <div className="relative inline-block rounded gap-2">
                  <div className="p-1 ml-7">
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={table.getIsAllColumnsVisible()}
                        onChange={handleToggleAll}
                        className="form-checkbox text-indigo-500 h-4 w-4 cursor-pointer"
                      />{" "}
                      <span className="cursor-pointer text-xs">{translate('shared.ordering-toggle-all')}</span>
                    </label>
                  </div>
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={table.getAllLeafColumns()}
                      strategy={verticalListSortingStrategy}
                    >
                      {table
                        .getAllLeafColumns()
                        .filter((column) => !column.getIsPinned())
                        .map((column) => {
                          return (
                            <SortableColumn
                              key={column.id}
                              id={column.id}
                              header={column.columnDef.header}
                              column={column}
                            />
                          );
                        })}
                    </SortableContext>
                  </DndContext>
                </div>
                {(showResetButton || hiddenColumns > 0) && (
                  <ButtonComponent
                    className="text-center w-full text-sm font-semibold rounded shadow-sm py-2 px-8
                                disabled:opacity-45 disabled:hover:bg-indigo-400 disabled:hover:text-indigo-400
                            bg-transparent text-md text-indigo-400 border-2 border-indigo-400 transition-all duration-200 hover:border-red-400 hover:bg-red-400 hover:text-white"
                    id="reset-order"
                    onClick={handleReset}
                    ref={null}
                    icon={null}
                    text={
                      helperTitle(translate('shared.reset-order'))
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </KeyboardEventHandler>
  );
};

export default OrderingComponent;

/* eslint-disable no-undef */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import DownloadModalStore from "@stores/DownloadModalStore";
import GlobalStore from "@stores/GlobalStore";


import SideBarItem from "./SideBarItem";
import clsx from "clsx";
import LogoutIcon from "@components/Icons/Logout";
import LanguageSelector from "@components/Icons/LanguageSelector";

const Sidebar = ({ menus }) => {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [downloadCenterState, setDownloadCenterState] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({});

  const {
    t: translate,
    i18n: { changeLanguage, language },
    i18n,
    ready,
  } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const [refreshComponent, setRefreshComponent] = useState(false);

  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    window.location.reload();
  };

  const handleClickOutside = () => {
    setOpen(false);
    setOpenSubMenus({});
  };

  const renderLangSelector = () => {
    return <LanguageSelector currentLanguage={currentLanguage} handleChangeLanguage={handleChangeLanguage} />;
    const isArabic = currentLanguage === "ar";
    const isEnglish = currentLanguage === "en";
    return (
      <div className="text-white text-center w-full ">
        {/* <h3>{currentLanguage}</h3> */}
        <button
          type="button"
          className="cursor-pointer pb-2"
          onClick={handleChangeLanguage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            direction="ltr"
            className="group transition-transform duration-500"
            key={`lang_switcher_${currentLanguage}`}
          >
            <image
              x="5"
              y="15"
              width="20"
              height="20"
              href={require("@assets/uk-flag.png")}
              className={clsx(
                "transition-all origin-left duration-500",
                !isEnglish
                  ? "group-hover:scale-150 group-hover:filter-none grayscale"
                  : "group-hover:grayscale  group-hover:scale-75"
              )}
              fontFamily="Arial, sans-serif"
              fontSize={isEnglish ? "26" : "22"}
              fontWeight={isEnglish ? "800" : "400"}
              textAnchor="middle"
            />
            {/* 🇬🇧
            </text> */}

            <image
              x="37"
              y="30"
              width="20"
              height="20"
              href={require("@assets/saudi-flag.png")}
              className={clsx(
                "transition-all origin-right duration-500",
                !isArabic
                  ? "group-hover:scale-150 group-hover:filter-none grayscale"
                  : "group-hover:grayscale group-hover:scale-75"
              )}
              fontFamily="Arial, sans-serif"
              fontSize={isArabic ? "28" : "22"}
              fontWeight={isArabic ? "800" : "400"}
              textAnchor="middle"
            />
            {/* 🇸🇦
            </text> */}

            {/* <path
              className="group-hover:stroke-[transparent]"
              d="M35,15 L28,45"
              stroke="#6b728020"
              strokeWidth="2"
            /> */}
          </svg>

          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 64 64"
            direction="ltr"
            className="group transition-transform duration-500"
            key={`lang_switcher_${currentLanguage}`}
          >
            <text
              x="12"
              y="35"
              className={clsx(
                "transition-all origin-center duration-500",
                !isEnglish
                  ? "group-hover:fill-[#f44336] group-hover:scale-125"
                  : "group-hover:fill-[#878787]"
              )}
              fontFamily="Arial, sans-serif"
              fontSize="24"
              fontWeight={isEnglish ? "800" : "400"}
              fill={isEnglish ? "#f44336" : "#878787"}
            >
              A
            </text>
            <text
              x="42"
              y="45"
              className={clsx(
                "transition-all origin-center duration-500",
                !isArabic
                  ? "group-hover:fill-[#4caf50] group-hover:scale-125"
                  : "group-hover:fill-[#878787]"
              )}
              fontFamily="Arial, sans-serif"
              fontSize="24"
              fontWeight={isArabic ? "800" : "400"}
              fill={isArabic ? "#4caf50" : "#878787"}
            >
              ع
            </text>

            <path
              d="M45,11 L28,55"
              stroke="#2196f3"
              strokeWidth="2"
              fill="none"
            />
          </svg> */}
        </button>
      </div>
    );
  };

  const renderDownloadCenterIcon = () => {
    return (
      <div className="text-white text-center w-full ">
        <button
          type="button"
          className={clsx(
            'cursor-pointer pb-2 grayscale hover:grayscale-0',
            DownloadModalStore.showing && "grayscale-0"
          )}
          onClick={() => {
            setOpen(false);
            DownloadModalStore.open()
          }}
          title="Download Center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 64 64"
            direction="ltr"
            className="group transition-transform duration-500"
            key={`download_center`}
          >
            <rect
              x="8"
              y="20"
              width="48"
              height="32"
              rx="4"
              ry="4"
              fill="#ffeb3b"
              stroke="#fbc02d"
              strokeWidth="2"
            />
            <rect
              x="8"
              y="16"
              width="24"
              height="8"
              rx="2"
              ry="2"
              fill="#ffeb3b"
              stroke="#fbc02d"
              strokeWidth="2"
            />

            <path
              d="M32,26 L32,42"
              stroke="#2196f3"
              strokeWidth="6"
              fill="none"
            />
            <path d="M26,36 L32,42 L38,36" fill="#2196f3" />

            <rect x="22" y="42" width="20" height="4" fill="#2196f3" />
          </svg>
        </button>
      </div>
    );
  };

  const Logout = () => {
    localStorage.removeItem('ttpUserId');
    GlobalStore.setGlobalSettings({
      ttpUserId: null,
      callback: () => {
        setTimeout(() => {
          window.location.href = "/login";
        }, 500);
      },
    });
  }

  return (
    <div
      className={clsx(
        `flex fixed  h-full`,
        open ? "z-[2001] w-full" : "z-[100]"
      )}
    >
      <div
        onClick={handleClickOutside}
        className={clsx(
          `flex absolute top-0 left-0`,
          open && " w-full h-full bg-gray-400 z-[-1] bg-opacity-70"
        )}
      ></div>
      <div>
        <div
          className={clsx(
            `absolute bg-gray-800 h-dvh p-5 pt-8 duration-300 flex flex-col justify-between`,
            open ? "w-full md:w-80 bg-blend-overlay bg-gray-900" : "w-[90px]"
          )}
        >
          <img
            src={require("@assets/control.png")}
            className={clsx(
              `absolute cursor-pointer ltr:-right-0 ltr:md:-right-3 rtl:-left-3 rtl:rotate-180 top-9 w-7 border-indigo-900 border-2 rounded-full`,
              !open && "ltr:rotate-180 rtl:rotate-0 ltr:!-right-3"
            )}
            onClick={() => {
              setOpenSubMenus({});
              setOpen(!open);
            }}
            alt="cursor-icon"
          />
          <div className={
            clsx(
              'flex gap-x-4 items-center',
              // open && 'justify-center'
            )
          }>
            <img
              src={require("@assets/pluto.png")}
              className={clsx(
                `cursor-pointer w-9 h-9 duration-[20s]`,
                open && "rotate-[360deg]"
              )}
              alt="main-icon"
            />
            <h1
              className={clsx(
                `text-white origin-left font-medium text-2xl duration-200`,
                !open && "scale-0"
              )}
            >
              Pluto
            </h1>
          </div>
          <div
            onClick={() => Logout()}
            className="flex flex-col items-center text-center justify-center gap-2 cursor-pointer text-white hover:text-red-600"
          >
            <span className="text-xs">
              {translate("login.logout")}
            </span>
            <LogoutIcon className="w-4 h-4 " />
          </div>
          <ul className="pt-6">
            {menus.map((Menu, index) => (
              <SideBarItem
                key={`sidebar_${index}`}
                index={index}
                Menu={Menu}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                open={open}
                setOpenSubMenus={setOpenSubMenus}
                openSubMenus={openSubMenus}
              />
            ))}
          </ul>

          <div className="relative flex flex-col gap-1">
            {renderDownloadCenterIcon()}
            {renderLangSelector()}

            <span
              className="cursor-help absolute -bottom-4 z-[0] text-xs text-gray-500 w-full text-center"
              onClick={() => {
                navigator.clipboard.writeText(VERSION || "");
              }}
            >
              {VERSION || ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useEffect, useState, useRef } from "react";
import {
  getExceptionDetails,
  connectPromotedRecords,
  PreviewManualMatching,
} from "@helpers/helpers";
import { useLocation } from "react-router-dom";
import CustomTable from "@components/CustomTable";
import Loader from "@components/LoaderComponent/loader";
import { CopyToCliboard } from "@components/CustomTable/helpers";
import Sticky from "react-sticky-el";
import OutstandingRecords from "../OutstandingRecords";
import ButtonComponent from "@components/ButtonComponent";

import SfmModalStore from "@stores/SfmModalStore";
import { GetSFMDisplay, FormatWithCommas } from "@helpers/helpers";

import clsx from "clsx";

import PopupComponent from "@components/PopupComponent";
import CorrectiveActionComponent from "@components/CorrectiveActionComponent";
import { useTranslation } from "react-i18next";

const ExceptionDetails = ({ resetComponent }) => {
  const { t: translate, i18n } = useTranslation();

  const [exceptionDetailsMatchingRecord, setExcpetionDetailMatchingRecord] =
    useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [attributesPopupOpen, setAttributesPopupOpen] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const [correctiveAction, setCorrectiveAction] = useState(null);
  const [selectedRows, setSelectedRows] = useState({});
  const [externalOutstandingRecords, setExternalOutstandingRecords] = useState(
    []
  );
  const [
    selectedOutstandingDestinationRows,
    setSelectedOutstandingDestinationRows,
  ] = useState({});
  const location = useLocation();

  const textColors = {
    CRITICAL: "text-red-900",
    HIGH: "text-red-600",
    MEDIUM: "text-red-400",
    LOW: "text-yellow-400",
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id"); // Assuming the ID is passed as a query parameter named "id"

    // Use the extracted ID as needed, for example:
    getExceptionDetails(id, (exceptionDetailsInfo) => {
      let matchingRecord = [];
      if (
        exceptionDetailsInfo?.PromotedRecords ||
        exceptionDetailsInfo?.MatchingRecord
      ) {
        matchingRecord = connectPromotedRecords(
          exceptionDetailsInfo?.PromotedRecords,
          [exceptionDetailsInfo?.MatchingRecord]
        );
      } else {
        const sfmData = GetSFMDisplay(exceptionDetailsInfo.SFM);
        SfmModalStore.open(sfmData, () => {
          window.location.href = "/exception-list";
        });
      }
      setCurrentId(id);
      setExcpetionDetailMatchingRecord(matchingRecord);
      setIsLoading(false);
    });
  }, [location]);

  const pinningInitialState = ["pin"];

  const columns = [
    {
      accessorKey: "Mismatches",
      header: translate("exception-details.columns.Mismatches"),
      size: 220,
      sortable: true,
      canPin: false,
      canFilter: false,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-1">
            {row.original.Mismatches.map((mismatch, index) => (
              <span
                key={index}
                className={`rounded-full items-center justify-between gap-2 w-fit font-normal text-sm border border-r-10 border-transparent `}
              >
                <span className={`${textColors[mismatch.MismatchSeverityTag]}`}>
                  {mismatch.MismatchName}
                </span>
              </span>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "MatchingSource",
      header: translate("exception-details.columns.MatchingSource"),
      size: 200,
      sortable: false,
      canPin: false,
      canFilter: false,
      cell: ({ row }) => {
        return (
          !!row.original.MatchingSource.MatchingSourceName &&
          row.original.MatchingSource.MatchingSourceName +
            ` (${translate("outstanding-records.columns.RunID")}: ${
              row.original.RunId
            })`
        );
      },
    },
    {
      accessorKey: "BusinessDay",
      header: translate("exception-details.columns.BusinessDay"),
      size: 150,
      sortable: true,
      canPin: false,
      canFilter: false,
      filterType: "date",
      cell: ({ row }) => {
        return (
          <span className="text-sm flex flex-col font-normal">
            <span>{row.original.BusinessDay}</span>
            <span>{row.original.BusinessDayInstance}</span>
          </span>
        );
      },
    },
    {
      accessorKey: "CreationDate",
      header: translate("exception-details.columns.CreationDate"),
      size: 150,
      sortable: true,
      canFilter: false,
      canPin: false,
      truncate: true,
      filterType: "date",
      splitCellValue: "T",
    },
    {
      accessorKey: "RecordMatchingKeys",
      header: translate("exception-details.columns.RecordMatchingKeys"),
      size: 150,
      sortable: true,
      canPin: false,
      cell: ({ row }) => {
        const values = row.original.RecordMatchingKeys;
        return (
          <div className="flex flex-col gap-1">
            {values.map((value, index) => {
              return <span key={index}>{value}</span>;
            })}
          </div>
        );
      },
    },
    {
      accessorKey: "SourceAmount",
      header: translate("exception-details.columns.SourceAmount"),
      size: 150,
      sortable: true,
      canPin: true,
      canFilter: false,
      filterType: "string",
      operator: "EQ",
      processFilterValue: (value, row) => {
        return row.SourceAmount.toString();
      },
      cellActionCheck: (value) => value > 0,
      cell: ({ row }) => {
        return (
          row.original.SourceAmount && (
            <span className="flex flex-col items-center justify-center group font-semibold">
              <span>{`${row.original.Currency} ${FormatWithCommas(
                row.original.SourceAmount
              )}`}</span>
              <span className="font-normal text-xs">{`${
                row.original.SourceRecords.length
              } ${
                row.original.SourceRecords.length === 1
                  ? translate("shared.record")
                  : translate("shared.records")
              }`}</span>
            </span>
          )
        );
      },
    },
    {
      accessorKey: "DestinationAmount",
      header: translate("exception-details.columns.DestinationAmount"),
      size: 200,
      sortable: true,
      canPin: true,
      canFilter: false,
      filterType: "string",
      operator: "EQ",
      filterCheck: (value) => {
        return value !== null;
      },
      cellActionCheck: (value) => value > 0,
      cell: ({ row }) => {
        return (
          row.original.DestinationAmount && (
            <span className="flex flex-col items-center justify-center group font-semibold">
              <span>{`${row.original.Currency} ${FormatWithCommas(
                row.original.DestinationAmount
              )}`}</span>
              <span className="font-normal text-xs">{`${
                row.original.DestinationRecords.length
              } ${
                row.original.DestinationRecords.length === 1
                  ? translate("shared.record")
                  : translate("shared.records")
              }`}</span>
            </span>
          )
        );
      },
    },
  ];

  const childrenColumns = [
    {
      accessorKey: "source",
      header: translate("exception-details.children-columns.source"),
      hideHeader: true,
      size: 20,
      cell: ({ row }) => {
        const colors = {
          Source: "text-green-500",
          Destination: "text-red-500",
        };
        return (
          <span
            className={clsx(
              `text-xs font-semibold flex gap-2 items-center`,
              colors[row.original.source] || "text-gray-400"
            )}
            title={row.original.source}
          >
            {row.original.source.split("")[0].toUpperCase()}
          </span>
        );
      },
    },
    {
      accessorKey: "ReconciliationStreamName",
      header: translate(
        "exception-details.children-columns.ReconciliationStreamName"
      ),
      size: 230,
      truncate: true,
    },
    {
      accessorKey: "MatchingSource",
      header: translate("exception-details.children-columns.DataSourceName"),
      size: 150,
      cell: ({ row }) => {
        return (
          !!row.original.MatchingSource.MatchingSourceName &&
          row.original.MatchingSource.MatchingSourceName +
            ` (${translate("outstanding-records.columns.RunID")}: ${
              row.original.RunId
            })`
        );
      },
    },
    {
      accessorKey: "AreaName",
      header: translate("exception-details.children-columns.AreaName"),
      size: 160,
    },
    {
      accessorKey: "OutletName",
      header: translate("exception-details.children-columns.OutletName"),
      size: 160,
    },
    {
      accessorKey: "TransactionTerminalCode",
      header: translate(
        "exception-details.children-columns.TransactionTerminalCode"
      ),
      size: 150,
    },
    {
      accessorKey: "RecordReference",
      header: translate("exception-details.children-columns.RecordReference"),
      size: 150,
    },
    {
      accessorKey: "RecordDate",
      header: translate("exception-details.children-columns.RecordDate"),
      size: 180,
      splitCellValue: "T",
    },
    {
      accessorKey: "RecordType",
      header: translate("exception-details.children-columns.RecordType"),
      size: 150,
    },
    {
      accessorKey: "Attributes",
      header: translate("exception-details.children-columns.Attributes"),
      size: 150,
      displayInPopup: true,
      popupCellDisplay: (value) => {
        return (
          <div className="relative text-indigo-400 font-semibold">
            ({value.length})
          </div>
        );
      },
      popupContent: (row) => {
        const value = row.Attributes;
        return (
          <div className="relative ">
            <div className="flex flex-col min-w-[400px] transition-all bg-primary-dark text-[#777f9e] text-14 rounded-lg px-[2px] py-[8px] mb-3 opacity-100">
              {value &&
                value.map((attr, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between gap-2 mb-[2px]"
                  >
                    <span className="py-[2px] whitespace-nowrap text-xs font-semibold">
                      {attr.Key}
                    </span>
                    <span className="relative py-[2px] flex gap-2 items-center px-[8px] whitespace-nowrap ltr:text-right rtl:text-left text-sm">
                      {attr.Value}
                      <div className=" absolute left-0 w-full justify-center flex opacity-0 hover:opacity-100 bg-gray-300 bg-opacity-50 rounded">
                        <CopyToCliboard size="5" value={attr.Value} />
                      </div>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "PaymentMethod",
      header: translate("exception-details.children-columns.PaymentMethod"),
      size: 180,
    },
    {
      accessorKey: "Amount",
      header: translate("exception-details.children-columns.Amount"),
      size: 130,
      cell: ({ row }) => {
        return (
          <span className="flex items-center justify-center group text-indigo-400 font-semibold">
            {`${row.original.Currency} ${
              row.original.SourceAmount || row.original.DestinationAmount
            }`}
          </span>
        );
      },
    },
  ];
  const noData = () => {
    return (
      <div className="text-center w-full flex flex-col items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
          />
        </svg>
        <span>{translate("shared.no-data")}</span>
      </div>
    );
  };

  const renderChildTable = (children) => {
    return (
      <div className="relative  max-h-[230px] overflow-y-auto pl-10">
        <table className="bg-white text-center border-t-2 border-gray-200 relative min-w-max">
          <thead>
            <tr className="bg-white text-15 text-center border-b-2 sticky top-0 w-full shadow-[0px 0px 1px #e1e1e1]">
              <th width={50}></th>
              {childrenColumns.map((column, index) => {
                return (
                  <th
                    key={index}
                    style={{ width: `${parseInt(column.size)}px` }}
                    className="py-[10px] px-[10px] whitespace-nowrap text-xs border-r-0 border-gray-100 font-normal text-gray-400"
                  >
                    {!column.hideHeader && column.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="">
            {children.map((child, index) => {
              const checked = selectedRows[child.PromotedRecordId];
              return (
                <tr
                  key={index}
                  className="text-secondary-dark hover:bg-bg-2 border-b border-line last:border-none"
                >
                  <td width={50}>
                    <input
                      type="checkbox"
                      className="size-4"
                      checked={checked}
                      onClick={() => {
                        setSelectedRows((prev) => {
                          const newState = { ...prev };

                          if (newState.hasOwnProperty(child.PromotedRecordId)) {
                            if (checked) {
                              delete newState[child.PromotedRecordId];
                            }
                          } else {
                            newState[child.PromotedRecordId] = true;
                          }

                          return newState;
                        });
                      }}
                    />
                  </td>
                  {childrenColumns.map((column, index) => {
                    let cellValue = child[column.accessorKey];

                    return (
                      <td
                        key={index}
                        style={{ width: `${parseInt(column.size)}px` }}
                        className="py-[10px] px-[10px] border-r-0 border-gray-100"
                      >
                        {column.displayInPopup ? (
                          <div className="flex justify-center items-center">
                            {attributesPopupOpen === child.PromotedRecordId && (
                              <PopupComponent
                                open={
                                  attributesPopupOpen === child.PromotedRecordId
                                }
                                rawValue={column.popupContent(child)}
                                setPopupOpen={setAttributesPopupOpen}
                                addBlur
                              />
                            )}

                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                setAttributesPopupOpen(child.PromotedRecordId);
                              }}
                            >
                              {column.popupCellDisplay(cellValue)}
                            </span>
                          </div>
                        ) : (
                          (typeof cellValue === "string" || column.cell) &&
                          (column.cell
                            ? column.cell({ row: { original: child } })
                            : cellValue)
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTables = (exceptionDetailsMatchingRecord) => {
    const sourceData = [];
    const destinationData = [];
    let matchingRecords = [];
    let childrenRecords = [];
    exceptionDetailsMatchingRecord?.forEach((record) => {
      matchingRecords.push(record);
      if (record.SourceRecords.length > 0) {
        record.SourceRecords.forEach((sourceRecord, index) => {
          sourceData.push(sourceRecord);
          childrenRecords.push({
            parentRecordID: record.MatchingRecordId,
            ...sourceRecord,
            source: "Source",
          });
        });
      }
      if (record.DestinationRecords.length > 0) {
        record.DestinationRecords.forEach((destinationRecord, index) => {
          destinationData.push(destinationRecord);
          childrenRecords.push({
            parentRecordID: record.MatchingRecordId,
            ...destinationRecord,
            source: "Destination",
          });
        });
      }
    });

    const combinedExceptionsDetailsKeys = [...Object.keys(selectedRows)];
    const combinedOutstandingRecordsKeys = [
      ...Object.keys(selectedOutstandingDestinationRows),
    ];

    const shouldShowSelectionDisplay =
      combinedExceptionsDetailsKeys.length > 0 ||
      combinedOutstandingRecordsKeys.length > 0;

    return (
      <>
        {!shouldShowSelectionDisplay && <div className="px-5 mt-2"></div>}
        {shouldShowSelectionDisplay && (
          <Sticky stickyClassName="bg-white z-[4] w-full shadow-[inset_0px_-3px_6px_#D6D6D6]">
            <div
              className={clsx(
                shouldShowSelectionDisplay
                  ? "opacity-100 py-5"
                  : "opacity-0 py-0",
                `px-5 w-full flex items-center justify-between gap-2 border-b-2 border-gray-100 transition-all duration-700`
              )}
            >
              <div className="flex w-full gap-2 justify-between flex-1">
                {shouldShowSelectionDisplay && (
                  <ButtonComponent
                    className="text-sm min-w-max h-fit p-2 rounded-lg px-4 cursor-pointer bg-white"
                    withoutBorder={true}
                    id="refresh-selection"
                    color="blue"
                    onClick={resetComponent}
                    ref={null}
                    icon={null}
                    text={translate("exception-details.refresh-records")}
                  />
                )}
                {shouldShowSelectionDisplay && (
                  <ButtonComponent
                    className="text-sm min-w-max h-fit  p-2 rounded-lg px-4 cursor-pointer bg-green-500 hover:bg-green-600 text-white"
                    withoutBorder={false}
                    id="corrective-results"
                    color="green"
                    disabled={
                      combinedExceptionsDetailsKeys.length === 0 ||
                      combinedOutstandingRecordsKeys.length === 0
                    }
                    onClick={() => {
                      setPopupLoading(true);
                      PreviewManualMatching(
                        externalOutstandingRecords,
                        exceptionDetailsMatchingRecord,
                        Object.keys(selectedRows),
                        Object.keys(selectedOutstandingDestinationRows),
                        (correctiveAction) => {
                          if (!correctiveAction.CorrectionResults) {
                            const sfmData = GetSFMDisplay(correctiveAction.SFM);
                            SfmModalStore.open(sfmData);
                            setPopupLoading(false);
                          } else {
                            setCorrectiveAction(correctiveAction);
                            setPopupLoading(false);
                            setPopupOpen(true);
                          }
                        }
                      );
                    }}
                    ref={null}
                    icon={null}
                    text={translate("exception-details.corrective-results")}
                  />
                )}
              </div>
            </div>
          </Sticky>
        )}
        <div className="p-5 w-full flex flex-col gap-5">
          <CustomTable
            language={i18n.language}
            externalData={matchingRecords}
            columnsWithoutPin={columns}
            loading={false}
            title={translate("exception-details.title")}
            pinningInitialState={pinningInitialState}
            setParentSelectedValues={() => {}}
            prefix="AttemptMatching_ExceptionDetails"
            pinnedRowHeight={62}
            maxTableHeight={400}
            showFilter={false}
            minimalTable={true}
            hideActionColumn={true}
            noData={noData}
            isExpandable={true}
            allowRowPinning={false}
            showAccessibility={true}
            pinCondition={(row) => {
              return true;
            }}
            expandedViewRow={(row) => {
              const children = childrenRecords.filter(
                (record) =>
                  record.parentRecordID === row.original.MatchingRecordId
              );
              return <div className="">{renderChildTable(children)}</div>;
            }}
            selectableCondition={(row, isHeader) => {
              return false;
            }}
            setExternalSelectedState={(selectedRows) => {
              setSelectedRows(selectedRows);
            }}
            autoFillData={false}
            rowCustomID={(row) => {
              return row.PromotedRecordId;
            }}
            customSubRows={(row) => {
              return row.DestinationRecords;
            }}
            initialTableVisibility={matchingRecords?.length > 0 ? true : false}
          />
        </div>
      </>
    );
  };

  return isLoading ? (
    <div className="fixed top-0 left-0 w-screen h-dvh bg-black opacity-50 flex justify-center items-center z-50">
      <Loader dots/>
    </div>
  ) : (
    <div>
      <div className="border-2 border-gray-200 rounded mr-5 text-black">
        {renderTables(exceptionDetailsMatchingRecord)}
      </div>
      <OutstandingRecords
        exceptionDetailsMatchingRecord={exceptionDetailsMatchingRecord}
        selectedOutstandingDestinationRows={selectedOutstandingDestinationRows}
        setSelectedOutstandingDestinationRows={
          setSelectedOutstandingDestinationRows
        }
        setExternalOutstandingRecords={setExternalOutstandingRecords}
        key={`outstandingRecords-${externalOutstandingRecords?.data?.length}`}
        recordId={currentId}
      />
      <PopupComponent
        open={popupOpen}
        rawValue={
          <CorrectiveActionComponent
            correctiveAction={connectPromotedRecords(
              correctiveAction?.PromotedRecords,
              correctiveAction?.CorrectionResults
            )}
            setPopupOpen={setPopupOpen}
            MatchingRequestId={correctiveAction?.ManualMatchingRequestId}
            resetComponent={resetComponent}
            translate={translate}
          />
        }
        setPopupOpen={setPopupOpen}
        addBlur
        hideCellValue={true}
        popupLoading={popupLoading}
        z="101"
      />
    </div>
  );
};
export default ExceptionDetails;

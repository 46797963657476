import React from "react";
import ChartComponent from "@components/ChartComponent";
const Welcome = () => {
  return (
    <div className="flex flex-col gap-2">
      <ChartComponent title="Business Day Closure Overview" />
      <div className="flex justify-between gap-2">
        <div className="w-1/2">
          <ChartComponent title="Week Closure Overview" />
        </div>
        <div className="w-1/2">
          <ChartComponent title="Month Closure Overview" />
        </div>
      </div>
    </div>
  );
  return (
    <div className="w-screen h-dvh flex items-center justify-center">
      Welcome
    </div>
  );
};

export default Welcome;

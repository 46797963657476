import React, { useState } from "react";
import Chart from "react-apexcharts";
import PopupComponent from "@components/PopupComponent";

import {analyzeData} from "@helpers/chartsHelpers"
import response from "./response.json"
const ChartComponent = ({title}) => {
  const [popupData, setPopupData] = useState(null);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
      events: {
        dataPointSelection: function (event, chartContext, config) {
          // const seriesIndex = config.seriesIndex;
          // const dataPointIndex = config.dataPointIndex;
          // const seriesName = chartContext.w.globals.seriesNames[seriesIndex];
          // const value = chartContext.w.globals.series[seriesIndex][dataPointIndex];
          // const category = chartContext.w.globals.labels[dataPointIndex];
          // console.log(`Clicked on ${seriesName} at ${category}: ${value}`);
          const dataPointIndex = config.dataPointIndex;
          // const category = chartContext.w.globals.labels[dataPointIndex];
          const category =
            chartContext.w.globals.lastXAxis.categories[dataPointIndex];
          // config.w.globals.lastXAxis.categories
          let datapointDisplay = "";
          console.log(chartContext.w.config.xaxis.categories);
          datapointDisplay += `Clicked on: ${category}`;

          chartContext.w.globals.seriesNames.forEach(
            (seriesName, seriesIndex) => {
              const value =
                chartContext.w.globals.series[seriesIndex][dataPointIndex];
              datapointDisplay += `${seriesName}: ${value.toLocaleString()}`;
            }
          );
          alert(datapointDisplay);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1, 2], // Enable data labels only for the line series
      offsetX: 0,
      offsetY: -10, // Adjust the vertical position of the data labels    
      style: {
        fontSize: "15px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        // colors: ["#ffbf00", "#fe0509"]
        colors: undefined
      },
      background: {
        enabled: true,
        foreColor: undefined,
        padding: 5,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        color: "black",
        opacity: 0.3,
      },
      formatter: function (val, opts) {
        return `${val.toFixed(2)}%`; // Format the data labels to 2 decimal places
      },

    },
    stroke: {
      show: true,
      width: [0, 4, 4],
      // curve: 'smooth'
      curve: "straight",
    },
    colors: ["#b5c7e7", "#ffbf00", "#fe0509"],
    xaxis: {
      // categories: [
      //   "10/10/2024 - 1.00",
      //   "11/10/2024 - 1.00",
      // ],
      categories: ['14-02-2024 1.00', '15-02-2024 1.00', '16-02-2024 1.00', '17-02-2024 1.00', '18-02-2024 1.00', '19-02-2024 1.00', '20-02-2024 1.00', '21-02-2024 1.00', '22-02-2024 1.00'],
      title: {
        text: "Business Day Instance",
      },
    },
    yaxis: [
      {
        title: {
          text: "Volume of records",
          // style: {
          //   color: '#FFD700'
          // }
        }, 
        labels: {
          formatter: function (val) {
            return `${val.toLocaleString()}`;
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Volume percentage",
          // style: {
          //   color: 'red'
          // }
        },
        labels: {
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
        },
      },
    ],
    fill: {
      opacity: [1, 0.8, 0.8],
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: "11px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
      x: {
        show: true,
        format: "dd MMM",
        formatter: function (val, config) {
          return config.w.globals.lastXAxis.categories[val];
        },
      },
      // y: {
      //   formatter: function (val) {
      //     return val;
      //   },
      // },
      marker: {
        show: true,
      },
      items: {
        display: "flex",
      },
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
      // y: {
      //   formatter: function (val) {
      //     return val + " counts"
      //   }
      // },
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        const category = w.globals.lastXAxis.categories[dataPointIndex];                        
        let tooltipContent = `<div class="flex flex-col gap-1 py-3 px-5" style="background: rgba(255, 255, 255, 0.5); border-radius: 5px;">
                                <span class="tooltip-category font-semibold">${category}</span>`;
        w.globals.seriesNames.forEach((seriesName, i) => {
          const value = series[i][dataPointIndex];
          let formattedValue = value.toLocaleString();
          if (seriesName === 'In-transit Volume' || seriesName === 'Exceptions Volume') {
            const recordCount = (value / 100 * w.globals.series[0][dataPointIndex]).toFixed(0);
            formattedValue = `${value.toFixed(2)}% (${recordCount} Records)`;
          } else if (seriesName === 'Business Day Values') {
            formattedValue = `${value.toLocaleString()} Records`;
          }
          tooltipContent += `<span class="tooltip-series"><span class="text-sm">${seriesName}:</span><span class="text-md font-semibold">${formattedValue}</span></span>`;
        });
        tooltipContent += `</div>`;
        return tooltipContent;
      }
      //specific
      // custom: function({ series, seriesIndex, dataPointIndex, w }) {
      //   const seriesName = w.globals.seriesNames[seriesIndex];
      //   const value = series[seriesIndex][dataPointIndex];
      //   const category = w.globals.labels[dataPointIndex];
      //   return `<div class="tooltip-custom">
      //             <!--<span class="tooltip-category">${category}</span>-->
      //             <span class="tooltip-series">${seriesName}: ${value.toLocaleString()}</span>
      //           </div>`;
      // },
      //all
      // custom: function({ series, seriesIndex, dataPointIndex, w }) {
      //   let tooltipContent = `<div class="flex flex-col gap-1 py-3 px-5">
      //                           <span class="tooltip-category">${w.globals.labels[dataPointIndex]}</span>`;
      //   w.globals.seriesNames.forEach((seriesName, i) => {
      //     const value = series[i][dataPointIndex];
      //     tooltipContent += `<span class="tooltip-series"><span class="text-sm ">${seriesName}:</span><span class="text-md font-semibold">${value.toLocaleString()}</span></span>`;
      //   });
      //   tooltipContent += `</div>`;
      //   return tooltipContent;
      // },
    },
    // legend: {
    //   position: 'top',
    //   horizontalAlign: 'right',
    //   offsetY: -10
    // }
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Business Day Values",
      type: "column",
      data: [81000, 62000, 87000, 39000, 90000, 65000, 67000, 59000, 63000]
    },
    {
      name: "In-transit Volume",
      type: "line",
      data: [5.49, 6.33, 3.32, 3.78, 5.60, 5.68, 4.16, 5.14, 6.10]
    },
    {
      name: "Exceptions Volume",
      type: "line",
      data: [0.07, 1.96, 0.07, 0.08, 0.08, 0.09, 0.13, 0.11, 0.06]
    },

  ]);
  const showPopup = (data) => {
    setPopupData(data || "No popup content available");
  };
  const [chartOptions2, setChartOptions2] = useState(analyzeData(response, showPopup).chartOptions)
  const [chartSeries2, setChartSeries2] = useState(analyzeData(response, showPopup).chartSeries)
  
  return (
    <div className="p-4 bg-white rounded shadow-md">
      <div className="flex justify-between mb-4">
        <div className="flex gap-4 items-center">
          <h2 className="text-xl font-bold">{title}</h2>
          <span>Filters goes here</span>
        </div>
        <span className="text-sm text-gray-500">
          Last updated: 2024-02-14 12:00:00
        </span>
      </div>
      <Chart
        options={chartOptions2}
        series={chartSeries2}
        type="line"
        height={350}
      />
      {/* <Chart
        options={chartOptions2}
        series={chartSeries2}
        type="line"
        height={350}
      /> */}
      {/* <img style={{width: '100%'}} src={chartImg}/> */}
      {popupData && (
        <PopupComponent
          open={popupData !== null}
          innerHtml={popupData}
          setPopupOpen={() => {
            setPopupData(null);
          }}
        />
      )}
    </div>
  );
};

export default ChartComponent;
